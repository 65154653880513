<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link back class="back" icon="fa fa-chevron-left" color="white" :icon-size="16">&nbsp;{{ $t('navigation.back') }}</f7-link>
            </f7-nav-left>
            <f7-nav-title class="grandhotel">{{ $t('staff.title') }}</f7-nav-title>
        </f7-navbar>

        <div class="p-2">
            <content-loader v-if="loading"></content-loader>
            <div v-else-if="!staffs.length" class="mt-5">
                <h2 class="text-pink text-center grandhotel p-3">{{ $t('staff.empty_data') }}</h2>
                <div v-if="showJoke" class="small text-center text-purple-title">{{ $t('staff.empty_data_joke') }}</div>
            </div>
            <div v-else v-for="(staff, i) in staffs" :key="'staff'+i" class="card card-3d p-3 mb-3">
                <div class="row">
                    <div class="col-3 align-self-center">
                        <avatar :avatar="staff.avatar_url" :size="70" class="rounded-circle mx-auto"></avatar>
                    </div>
                    <div class="col-9 align-self-center">
                        <h3 class="grandhotel text-purple-title ellipsis mb-0">{{staff.first_name + ' ' + staff.last_name | capitalize}}</h3>
                        <div v-if="staff.job" class="text-secondary">
                            <strong>{{staff.job}}</strong>
                        </div>
                        <div v-if="staff.biography" class="text-secondary mt-2 biography">{{staff.biography}}</div>
                    </div>
                </div>
            </div>
        </div>
    </f7-page>
</template>

<script>
    import { f7Page, f7Navbar, f7NavLeft, f7NavTitle, f7Link } from 'framework7-vue';

    export default {
        components: {
            f7Page,
            f7Navbar,
            f7NavLeft,
            f7NavTitle,
            f7Link,
        },
        data() {
            return {
                staffs: [],
                loading: false
            }
        },
        computed: {
            showJoke() {
                return this.$shouldDisplayJoke(this.$nursery.id);
            }
        },
        mounted() {
            this.getStaffs();
        },
        methods: {
            getStaffs() {
                this.loading = true;

                this.$http.get('/staffs').then((response) => {
                    this.loading = false;
                    this.staffs = response.data;
                }).catch(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>

<style lang="scss">
    .biography {
        white-space: pre-wrap;
    }
</style>