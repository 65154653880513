<template>
    <f7-page name="other">
        <f7-navbar>
            <f7-nav-left class="mr-5"></f7-nav-left>
            <f7-nav-title class="grandhotel">{{ $t('tools.title') }}</f7-nav-title>
            <f7-nav-right class="ml-0">
                <f7-link v-if="!showPanel" icon="fa fa-cog" color="white" :icon-size="22" panel-open="right" id="btnSettings"></f7-link>
                <f7-link v-else icon="fa fa-times" color="white" :icon-size="22" panel-close id="btnSettings"></f7-link>
            </f7-nav-right>
        </f7-navbar>

        <div v-if="has_access" class="text-center p-4 mt-1">
            <div class="mb-4">
                <a href="/kids/" class="btn btn-purple text-white w-100 p-2">
                    <i class="fa fa-heart-circle mr-2"/>{{ $t('kids.title') }}
                </a>
            </div>

            <h6 class="text-left text-pink text-uppercase" style="font-size: 11px;">{{ $t('tools.my_tools') }}</h6>
            <hr class="mt-2 mb-4">
            <div class="row">
                <div class="col-4 btn-circle mb-3">
                    <f7-link href="/photos/" icon="fal fa-photo-video" :icon-size="26" class="btn btn-purple"></f7-link>
                    <label class="text-black">{{ $t('photos.title') }}</label>
                </div>
                <div class="col-4 btn-circle">
                    <f7-link href="/invoices/" icon="fal fa-file-invoice" :icon-size="26" class="btn btn-purple">
                        <span
                            v-if="badges.invoices"
                            class="badge-notification btn-pink animate__animated animate__bounce">{{ badges.invoices }}</span>
                    </f7-link>
                    <label class="text-black">{{ $t('invoices.title') }}</label>
                </div>
                <div class="col-4 btn-circle">
                    <f7-link href="/payments/" icon="fal fa-money-bill-wave" :icon-size="25" class="btn btn-purple"></f7-link>
                    <label class="text-black">{{ $t('payments.title') }}</label>
                </div>
                <div class="col-4 btn-circle">
                    <f7-link href="/info/" icon="fal fa-map-signs" :icon-size="26" class="btn btn-purple"></f7-link>
                    <label class="text-black">{{ $t('details.title') }}</label>
                </div>
                <div class="col-4 btn-circle">
                    <f7-link href="/documents/" icon="fal fa-copy" :icon-size="26" class="btn btn-purple">
                        <span
                            v-if="badges.documents"
                            class="badge-notification btn-pink animate__animated animate__bounce">{{ badges.documents }}</span>
                    </f7-link>
                    <label class="text-black">{{ $t('documents.title') }}</label>
                </div>
                <div class="col-4 btn-circle">
                    <f7-link href="/team/" icon="fal fa-users" :icon-size="26" class="btn btn-purple"></f7-link>
                    <label class="text-black">{{ $t('staff.title') }}</label>
                </div>
            </div>

            <h6 class="text-left text-pink text-uppercase mb-0 mt-4" style="font-size: 11px;">{{ $t('tools.about') }}</h6>
            <hr class="mt-2 mb-3">
            <div class="text-center">
                <h1 class="grandhotel mb-1" style="color: #2C97D3;">meeko</h1>
                <label>www.meeko.pro</label>
                <div v-if="version" class="mt-3 small">v{{ version }}</div>
            </div>
        </div>
        <check-access v-else v-model="has_access"></check-access>

        <right-panel v-model="showPanel"></right-panel>

    </f7-page>
</template>

<script>
    import RightPanel from '@/views/Other/RightPanel';

    import { f7Page, f7Navbar, f7NavLeft, f7NavTitle, f7NavRight, f7Link } from 'framework7-vue';

    export default {
        components: {
            RightPanel,
            f7Page,
            f7Navbar,
            f7NavLeft,
            f7NavTitle,
            f7NavRight,
            f7Link,
        },
        data() {
            return {
                nursery: this.$nursery,
                showPanel: false,
                has_access: this.$http_bus.has_access,
                badges: {
                    invoices: null,
                    documents: null
                },
                version: process.env.VUE_APP_VERSION,
            }
        },
        beforeDestroy() {
            this.$notification_bus.$off('refreshBadgesOther');
        },
        mounted() {
            this.$notification_bus.$on('refreshBadgesOther', () => {
                this.initBadges();
            });
            
            this.initBadges();
        },
        methods: {
            initBadges() {
                this.badges.invoices = this.$notification_bus.badges.invoices > 9 ? '+' : this.$notification_bus.badges.invoices;
                this.badges.documents = this.$notification_bus.badges.documents > 9 ? '+' : this.$notification_bus.badges.documents;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .btn-circle {
        padding: 5px;

        .btn {
            display: inline-flex;
            width: 55px;
            height: 55px;
            border-radius: 50%;

            &.btn-purple {
                background-color: #eee6ff;
                color: #6738E0;
            }
        }

        .badge-notification {
            position: absolute;
            top: 3px;
            right: 0px;
        }

        label {
            font-size: 12px;
            font-weight: 500;
            width: 100%;
            margin-top: 10px;
        }
    }
</style>