<template>
    <div class="datepicker">
        <datepicker
            v-model="date"
            :language="lang"
            monday-first
            format="D d MMM yyyy"
            @selected="changeDate"
        />
    </div>
</template>

<script>
    import moment from "moment";

    import Datepicker from "vuejs-datepicker";
    import { fr, en, es, de, it } from "vuejs-datepicker/dist/locale";

    export default {
        components: {
            Datepicker
        },
        props: {
            value: {}
        },
        data() {
            return {
                date: this.currentDate,
                lang: fr,
            };
        },
        watch: {
            value(val) {
                this.date = val;
            }
        },
        mounted() {
            this.changeDate(this.date);
            let lang = window.localStorage.getItem('lang');
            switch (lang) {
                case 'fr':
                    this.lang = fr;
                    return;
                case 'en':
                    this.lang = en;
                    return;
                case 'es':
                    this.lang = es;
                    return;
                case 'de':
                    this.lang = de;
                    return;
                case 'it':
                    this.lang = it;
                    return;
                default:
                    this.lang = fr;
            }
        },
        methods: {
            changeDate(newDate) {
                newDate = moment(newDate).format();
                this.date = newDate;
                this.$emit("input", newDate);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .datepicker {
        display: inline-block;
    }
</style>

<style lang="scss">
    .vdp-datepicker {
        position: relative;
        input {
            width: auto;
            text-align: center;
            z-index: 10000;
            border: none;
            background: transparent;
            font-family: "Grand Hotel";
            color: #6535e8;
            font-size: 22px;
        }
        .vdp-datepicker__calendar {
            position: fixed;
            left: 50%;
            transform: translate(-50%, 0%);

            margin-top: 5px;
            font-size: 16px;
            padding: 5px;
            color: #5c7276;
            font-weight: 500;
            line-height: 2.2;
            border-radius: 10px;
            &:before {
                content: "";
                position: absolute;
                left: 140px;
                top: -10px;
                width: 0px;
                height: 0px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #949494;
            }
            .cell {
                &.day-header {
                    color: #188fd4;
                }
                &.selected {
                    background: #f22c84;
                    color: white;
                    border-radius: 10px;
                    padding: 0;
                    &.day {
                        border-radius: 100%;
                    }
                }
                &:hover {
                    border: 0;
                }
                &.today:not(.selected) {
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 2px;
                        right: 6px;
                        height: 3px;
                        width: 25px;
                        background: #2997d7;
                    }
                }
            }
        }
    }
</style>