<template>
    <div id="app">
        <f7-app
            v-if="appReady && !LULoading"
            :params="f7params"
        >
            <f7-view
                v-if="login"
                id="main-view"
                main
                url="/home/"
            />
            <page-login v-else />
        </f7-app>
        <template v-else>
            <div
                class="d-flex align-items-center rounded btn-pink text-center"
                style="height: 100vh"
            >
                <h2 class="grandhotel text-center w-100 mb-5 text-white">
                    <i class="fal fa-cog fa-spin mr-2" />
                    {{ $t('navigation.app_loading') }}
                </h2>
            </div>
        </template>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Echo from 'laravel-echo';
    import moment from 'moment';
    import $ from 'jquery';
    import i18n from '@/i18n';

    import routes from '@/utils/routes';

    import PageLogin from '@/views/Login';

    import { f7App, f7View } from 'framework7-vue';

    import { Capacitor } from '@capacitor/core';
    import { App } from '@capacitor/app';
    import { PushNotifications } from '@capacitor/push-notifications';
    import {Deploy} from 'cordova-plugin-ionic';

    export default {
        components: {
            PageLogin,
            f7App,
            f7View,
        },
        name: 'App',
        data() {
            return {
                tab: 'home',
                login: this.$http_bus.connected,
                appReady: false,
                f7params: {
                    id: 'com.meeko.family',
                    theme: 'ios',
                    routes,
                    cache: false,
                    statusbarOverlay: true,
                    animateNavBackIcon: true,
                    lazy: {
                        threshold: 100,
                        sequential: false,
                        placeholder: '/assets/img/no-avatar.png',
                    },
                    statusbar: {
                        iosOverlaysWebView: true,
                        iosTextColor: 'white',
                        androidOverlaysWebView: true,
                        androidTextColor: 'white',
                    },
                },
                userLoading: false,
                // Live update data
                LUConfig: null,
                LUNewVersion: false,
                LULoading: false,
            };
        },

        created() {
            this.appReady = true;

            // Prevent backdrop over modal
            $(document).on('show.bs.modal', '.modal', () => {
                $('.modal').appendTo('#app');
            });

            // Open dynamic links in brwoser
            $(document).on('click', 'a[href^="http"], a[href^="mailto"]', (e) => {
                this.$openUrl(e.target.href);
                e.preventDefault();
            });

            this.$bus.$on('login', (connected) => {
                window.localStorage.setItem('connected', connected);
                this.login = connected;
                this.tab = 'home';

                this.$root.login = connected;

                if (connected) {
                    this.sendToken('register', this.$getDeviceInfo());
                    this.getNurseries();
                    this.initBugsnagClient();

                    this.getUser().then(() => {
                        this.initLiveUpdate();
                    });
                } else if (localStorage.getItem('registrationId')) {
                    this.sendToken('unregister');
                }

                this.initEcho();
            });

            this.login ? this.initBugsnagClient() : null;
            this.login ? this.initEcho() : null;
        },

        mounted() {
            App.addListener('appStateChange', (state) => {
                if (state.isActive) {
                    this.$bus.$emit('resume');
                    this.initPush();
                    this.getUser().then(() => {
                        this.initLiveUpdate();
                    });

                    const deviceInfo = this.$getDeviceInfo();
                    if(deviceInfo.shouldUpdate) {
                        this.sendToken('register', deviceInfo);
                    }
                }
            });

            setTimeout(() => {
                this.initPush();
            }, 3000);

            this.getUser().then(() => {
                this.initLiveUpdate();
            });

            this.setLang();
            this.getNurseries();
        },

        destroyed() {
            App.removeAllListeners();
        },

        methods: {
            sendToken(path, deviceInfo) {
                const api_token = localStorage.getItem('api_token');
                if(api_token) {
                    let url = null;
                    if (Capacitor.getPlatform() === 'android') {
                        url = '/gcm/' + path;
                    } else if (Capacitor.getPlatform() === 'ios') {
                        url = '/apn/' + path;
                    }

                    if (url) {
                        const token = localStorage.getItem('registrationId');
                        this.$http.post(url, {
                            token: token ? token : undefined,
                            device_os: deviceInfo ? deviceInfo.device_os : undefined,
                            device_os_version: deviceInfo ? deviceInfo.device_os_version : undefined,
                            device_app_version: deviceInfo ? deviceInfo.device_app_version : undefined,
                        });
                    }
                }
            },

            getNurseries() {
                if (this.login) {
                    this.$http.get('/nurseries').then((response) => {
                        this.loading = false;
                        Vue.prototype.$nurseries = response.data;
                        if (response.data.length) {
                            Vue.prototype.$nursery = response.data.find(
                                (item) => item.id.toString() === this.$http_bus.nursery_id
                            );
                        }
                    });
                }
            },

            getUser() {
                if (this.login) {
                    this.userLoading = true;
                    return this.$http.get('/user').then((response) => {
                        this.$http_bus.initUser(response.data);
                        this.userLoading = false;
                    }).catch(() => {
                        this.userLoading = false;
                    });
                }

                return Promise.resolve();
            },

            setLang() {
                let lang = window.localStorage.getItem('lang');
                if (lang) {
                    i18n.locale = lang;
                } else {
                    let lang = navigator.language || navigator.userLanguage;
                    i18n.locale =
                        lang && lang.substring(0, 2) ? lang.substring(0, 2) : 'fr';
                    window.localStorage.setItem('lang', i18n.locale);
                }
                moment.locale(i18n.locale);
            },

            initEcho() {
                let api_token = localStorage.getItem('api_token');
                if (api_token && window.PUSHER_KEY) {
                    window.Echo = new Echo({
                        authEndpoint: window.API_URL + '/v1/broadcasting/auth',
                        broadcaster: 'pusher',
                        key: window.PUSHER_KEY,
                        cluster: 'eu',
                        encrypted: true,
                        auth: {
                            headers: {
                                Accept: 'application/json ',
                                Authorization: 'Bearer ' + api_token,
                            },
                        },
                    });
                }
            },

            async initPush() {
                if (Capacitor.isNativePlatform()) {
                    const self = this;

                    // PushNotifications.requestPermissions().then((result) => {
                    //     if (result.granted) {
                    //         PushNotifications.register();
                    //     } else {
                    //         console.log('Error on request push permission');
                    //     }
                    // });

                    PushNotifications.removeAllListeners();
                    PushNotifications.removeAllDeliveredNotifications();

                    if (typeof PushNotifications.requestPermissions === 'function') {
                        let permStatus = await PushNotifications.checkPermissions();
                        if (permStatus.receive === 'prompt') {
                            permStatus = await PushNotifications.requestPermissions();
                        }

                        if (permStatus.receive !== 'granted') {
                            throw new Error('User denied permissions!');
                        }

                        await PushNotifications.register();
                    } else if (typeof PushNotifications.requestPermission === 'function') {
                        PushNotifications.requestPermission().then((result) => {
                            if (result.granted) {
                                PushNotifications.register();
                            } else {
                                console.log('Error on request push permission');
                            }
                        });
                    }

                    // On success, we should be able to receive notifications
                    PushNotifications.addListener('registration', (token) => {
                        if(token && token.value) {
                            console.log(
                                'Push registration success, token: ' + token.value
                            );
                            const oldRegId = localStorage.getItem('registrationId');
                            if (oldRegId !== token.value) {
                                localStorage.setItem('registrationId', token.value);
                                if (self.login) {
                                    self.sendToken('register');
                                }
                            }
                        }
                    });

                    // Some issue with our setup and push will not work
                    PushNotifications.addListener('registrationError', (error) => {
                        console.log(
                            'Error on registration: ' + JSON.stringify(error)
                        );
                    });

                    // Get all notifications visible in the Notification Center of the device
                    // Create a local badge for each notification
                    PushNotifications.getDeliveredNotifications().then(({notifications}) => {
                        notifications.forEach((notification) => {
                            console.log(notification);
                            if (Capacitor.getPlatform() === 'ios') {
                                notification = notification.data.aps;
                            } else if (Capacitor.getPlatform() === 'android') {
                                notification = notification.data;
                            }
                            self.$notification_bus.setLocalBadge(notification);
                        });
                    });

                    // Show us the notification payload if the app is open on our device
                    PushNotifications.addListener(
                        'pushNotificationReceived',
                        (notification) => {
                            console.log(
                                'Notification Open: ' + JSON.stringify(notification)
                            );

                            self.$toastSuccess(
                                notification.body
                                    ? notification.body
                                    : notification.title
                            );

                            if (Capacitor.getPlatform() === 'ios') {
                                notification = notification.data.aps;
                            } else if (Capacitor.getPlatform() === 'android') {
                                notification = notification.data;
                            }
                            self.$notification_bus.setLocalBadge(notification);
                        }
                    );

                    // Method called when tapping on a notification
                    PushNotifications.addListener(
                        'pushNotificationActionPerformed',
                        (notification) => {
                            console.log(
                                'Notification Open: ' + JSON.stringify(notification)
                            );

                            if (Capacitor.getPlatform() === 'ios') {
                                notification = notification.notification.data.aps;
                            } else if (Capacitor.getPlatform() === 'android') {
                                notification = notification.notification.data;
                            }

                            if (notification && notification.type) {
                                const type = notification.type;
                                if (type === 'new-document-v1') {
                                    self.$bus.$emit(
                                        'navigateTo',
                                        'other',
                                        'documents'
                                    );
                                } else if (type === 'updated-document-v1') {
                                    self.$bus.$emit(
                                        'navigateTo',
                                        'other',
                                        'documents'
                                    );
                                } else if (type === 'new-post-v1') {
                                    self.$bus.$emit('navigateTo', 'news');
                                } else if (type === 'new-photo-v1') {
                                    // this.$bus.$emit('navigateTo', 'home');
                                } else if (type === 'new-invoice-v1') {
                                    self.$bus.$emit(
                                        'navigateTo',
                                        'other',
                                        'invoices'
                                    );
                                } else if (type === 'new-message-v1') {
                                    self.$bus.$emit('navigateTo', 'contact');
                                }
                            }
                        }
                    );
                }
            },

            initBugsnagClient() {
                if (typeof bugsnagClient !== 'undefined') {
                    // eslint-disable-next-line
                    bugsnagClient.user = {
                        nursery: localStorage.getItem('nursery_id'),
                        device_id: localStorage.getItem('user_id'),
                        internal_version: process.env.VUE_APP_VERSION,
                    };
                }
            },

            async getLiveUpdateConfig() {
                this.LUConfig = await Deploy.getConfiguration();
            },
            async checkLiveUpdateAvailable() {
                this.LUNewVersion = await Deploy.checkForUpdate();
            },
            async initLiveUpdate() {
                // Check current LU config on device
                await this.getLiveUpdateConfig();
                // Check if device is configured on same channel as user
                if (this.$user && this.$user.release_channel && this.LUConfig.channel !== this.$user.release_channel) {
                    await Deploy.configure({channel: this.$user.release_channel});
                    await this.getLiveUpdateConfig();
                }
                await this.checkLiveUpdateAvailable();
                // If new version of device channel exist, we update the app
                if (this.LUNewVersion.available) {
                    this.LULoading = true;
                    try {
                        await Deploy.downloadUpdate();
                        await Deploy.extractUpdate();
                        await Deploy.reloadApp();
                        this.LULoading = false;
                    } catch (e) {
                        this.$toastWarning(this.$t('common:errors.generic'));
                        this.LULoading = false;
                    }
                }
            },
        },
    };
</script>

<style lang="scss">
    @import './sass/style';

    #app {
        background: #f2e1ff;
    }
</style>
