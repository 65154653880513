<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link back class="back" icon="fa fa-chevron-left" color="white" :icon-size="16">&nbsp;{{ $t('navigation.back') }}</f7-link>
            </f7-nav-left>
            <f7-nav-title class="grandhotel">{{ $t('payments.title') }}</f7-nav-title>
        </f7-navbar>

        <div class="p-2">
            <content-loader v-if="loading" template="list"></content-loader>
            <template v-else>
                <div class="text-center">
                    <i class="fa fa-info-circle text-info mr-1"></i>
                    <span class="small">{{ $t('payments.list_tip') }}</span>
                </div>
                <div v-if="!payments.length" class="mt-5">
                    <template v-if="showJoke">
                        <h2 class="text-pink text-center grandhotel p-3">{{ $t('payments.empty_data') }}</h2>
                        <div class="small text-center text-purple-title">{{ $t('payments.empty_data_joke') }}</div>
                    </template>
                    <h2 v-else class="text-pink text-center grandhotel p-3">{{ $t('payments.module_disabled') }}</h2>
                </div>
                <div v-else v-for="(payment, i) in payments" :key="'payment'+i" class="card p-2">
                    <h6 class="m-1">
                        <strong v-if="payment.rejected" class="text-danger">
                            <i class="fa fa-reply mr-1"/>{{payment.amount | currency(nursery)}}
                        </strong>
                        <strong v-else class="text-primary">{{payment.amount | currency(nursery)}}</strong>
                        <span> - n° {{payment.invoice_no}}</span>
                    </h6>
                    <div class="mx-1">
                        {{payment.type | humanPayment(self)}}
                        <strong>{{payment.paid_at | formatDate($t('date_format.days_months_years.simple'))}}</strong>
                        <span v-if="payment.payment_no">({{payment.payment_no}})</span>
                    </div>
                </div>
            </template>
        </div>
    </f7-page>
</template>

<script>
    import { f7Page, f7Navbar, f7NavLeft, f7NavTitle, f7Link } from 'framework7-vue';

    export default {
        components: {
            f7Page,
            f7Navbar,
            f7NavLeft,
            f7NavTitle,
            f7Link,
        },
        data() {
            return {
                payments: [],
                nursery: this.$nursery,
                loading: false
            }
        },
        computed: {
            self() {
                return this;
            },
            showJoke() {
                return this.$shouldDisplayJoke(this.nursery.id);
            }
        },
        mounted() {
            this.getPayments();
        },
        methods: {
            getPayments() {
                this.loading = true;

                this.$http.get('/payments').then((response) => {
                    this.loading = false;
                    this.payments = response.data;
                }).catch(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>