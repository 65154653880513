<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link back class="back" icon="fa fa-chevron-left" color="white" :icon-size="16">&nbsp;{{ $t('navigation.back') }}</f7-link>
            </f7-nav-left>
            <f7-nav-title class="grandhotel">{{ $t('invoices.title') }}</f7-nav-title>
            <f7-subnavbar sliding v-if="attestations.length">
                <f7-segmented raised>
                    <f7-button tab-link="#tab1" tab-link-active>{{ $t('invoices.invoices') }}</f7-button>
                    <f7-button tab-link="#tab2">{{ $t('invoices.certificates') }}</f7-button>
                </f7-segmented>
            </f7-subnavbar>
        </f7-navbar>

        <f7-tabs>
            <f7-tab id="tab1" :class="{'pt-5': attestations.length}" tab-active>
                <div class="p-2">
                    <content-loader v-if="loading" template="list"></content-loader>
                    <div v-else-if="!invoices.length" class="mt-5">
                        <template v-if="showJoke">
                            <h2 class="text-pink text-center grandhotel">{{ $t('invoices.empty_data') }}</h2>
                            <div class="small text-center text-purple-title">{{ $t('invoices.empty_data_joke') }}</div>
                        </template>
                        <h2 v-else class="text-pink text-center grandhotel">{{ $t('invoices.module_disabled') }}</h2>
                    </div>
                    <div v-else v-for="(invoice, i) in invoices" :key="'invoice'+i" @click="$openUrl(invoice.pdf_url)" class="card p-2">
                        <div class="row">
                            <div class="col-10">
                                <h6 class="m-1">
                                    <span :class="$getInvoiceClass(invoice.state)">{{invoice.no}}</span>
                                    <strong> - {{invoice.grand_total | currency(nursery)}}</strong>
                                </h6>
                                <div class="mx-1 ellipsis">
                                    <strong>{{invoice.date | formatDate($t('date_format.days_months_years.simple'))}}</strong>
                                    <span> - {{invoice.kid_name}}</span>
                                </div>
                            </div>
                            <div class="col-2 align-self-center text-center pt-1">
                                <i class="fa text-muted" :class="invoice.loading ? 'fa-circle-notch fa-spin' : 'far fa-download'" style="font-size: 20px;"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </f7-tab>
            <f7-tab id="tab2" class="pt-5">
                <div class="p-2">
                    <content-loader v-if="loading" template="list"></content-loader>
                    <div v-else v-for="(attestation, i) in attestations" :key="'attestation'+i" @click="$openUrl(attestation.caf_certif_url)" class="card p-2">
                        <div class="row">
                            <div class="col-10">
                                <h6 class="m-1 text-purple-title">
                                    <span>{{attestation.date | formatDate($t('date_format.days_months_years.simple'))}}</span>
                                    <strong> - {{attestation.grand_total | currency(nursery)}}</strong>
                                </h6>
                                <div class="mx-1 ellipsis">
                                    <strong>{{attestation.no}}</strong>
                                    <span> - {{attestation.kid_name}}</span>
                                </div>
                            </div>
                            <div class="col-2 align-self-center text-center pt-1">
                                <i class="fa text-muted" :class="attestation.loading ? 'fa-circle-notch fa-spin' : 'far fa-download'" style="font-size: 20px;"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </f7-tab>
        </f7-tabs>
    </f7-page>
</template>

<script>
    import { f7Page, f7Navbar, f7NavLeft, f7NavTitle, f7Link, f7Subnavbar, f7Segmented, f7Button, f7Tabs, f7Tab } from 'framework7-vue';

    export default {
        components: {
            f7Page,
            f7Navbar,
            f7NavLeft,
            f7NavTitle,
            f7Link,
            f7Subnavbar,
            f7Segmented,
            f7Button,
            f7Tabs,
            f7Tab,
        },
        data() {
            return {
                invoices: [],
                nursery: this.$nursery,
                loading: false
            }
        },
        mounted() {
            this.$notification_bus.resetLocalBadge('new-invoice-v1');
            
            this.getInvoices();
        },
        computed: {
            attestations() {
                return this.invoices.filter((item) => typeof item.caf_certif_url !== 'undefined');
            },
            showJoke() {
                return this.$shouldDisplayJoke(this.nursery.id);
            }
        },
        methods: {
            getInvoices() {
                this.loading = true;

                this.$http.get('/invoices').then((response) => {
                    this.loading = false;
                    this.invoices = response.data;
                }).catch(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>