<template>
    <div class="mt-4">

        <template v-if="template === 'post'">
            <vue-content-loading :width="300" :height="80" secondary="#dacbec">
                <circle cx="35" cy="30" r="20" />
                <rect x="75" y="13" rx="4" ry="4" width="180" height="12" />
                <rect x="75" y="37" rx="4" ry="4" width="120" height="10" />
                <rect x="75" y="57" rx="4" ry="4" width="120" height="10" />
            </vue-content-loading>
            <hr>
            <vue-content-loading :width="300" :height="80" secondary="#dacbec">
                <circle cx="35" cy="30" r="20" />
                <rect x="75" y="13" rx="4" ry="4" width="180" height="12" />
                <rect x="75" y="37" rx="4" ry="4" width="120" height="10" />
                <rect x="75" y="57" rx="4" ry="4" width="120" height="10" />
            </vue-content-loading>
            <hr>
            <vue-content-loading :width="300" :height="80" secondary="#dacbec">
                <circle cx="35" cy="30" r="20" />
                <rect x="75" y="13" rx="4" ry="4" width="180" height="12" />
                <rect x="75" y="37" rx="4" ry="4" width="120" height="10" />
                <rect x="75" y="57" rx="4" ry="4" width="120" height="10" />
            </vue-content-loading>
            <hr>
        </template>

        <template v-else-if="template === 'news'">
            <vue-content-loading :width="300" :height="150" secondary="#dacbec">
                <rect x="15" y="0" rx="4" ry="4" width="270" height="150"></rect>
            </vue-content-loading>
            <hr>
            <vue-content-loading :width="300" :height="60" secondary="#e8ced9">
                <rect x="15" y="0" rx="4" ry="4" width="270" height="60"></rect>
            </vue-content-loading>
            <hr>
            <vue-content-loading :width="300" :height="90" secondary="#dacbec">
                <rect x="15" y="0" rx="4" ry="4" width="270" height="90"></rect>
            </vue-content-loading>
            <hr>
        </template>

        <template v-else-if="template === 'list'">
            <vue-content-loading :width="300" :height="80" secondary="#dacbec">
                <rect x="15" y="0" rx="4" ry="4" width="270" height="20"></rect>
                <rect x="25" y="30" rx="4" ry="4" width="260" height="10"></rect>
                <rect x="25" y="50" rx="4" ry="4" width="260" height="10"></rect>
            </vue-content-loading>
            <vue-content-loading :width="300" :height="80" secondary="#e8ced9">
                <rect x="15" y="0" rx="4" ry="4" width="270" height="20"></rect>
                <rect x="25" y="30" rx="4" ry="4" width="260" height="10"></rect>
                <rect x="25" y="50" rx="4" ry="4" width="260" height="10"></rect>
            </vue-content-loading>
        </template>
    </div>
</template>

<script>
    import { VueContentLoading } from 'vue-content-loading';

    export default {
        components: {
            VueContentLoading
        },
        props: {
            template: {
                type: String,
                default: 'post'
            }
        }
    }
</script>