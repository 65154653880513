<template>
    <f7-page>
        <f7-toolbar tabbar labels position="bottom">
            <f7-link tab-link route-tab-id="home" href="./" :icon-size="24">
                <i
                    class="fal fa-newspaper"
                    style="font-size: 23px;">
                    <span
                        v-if="badges.home"
                        class="badge-notification btn-pink animate__animated animate__infinite animate__pulse">{{ badges.home }}</span>
                </i>
                <span class="tabbar-label">{{ $t('home.title') }}</span>
            </f7-link>
            <f7-link tab-link route-tab-id="news" href="./news/" :icon-size="23">
                <i
                    class="fal fa-calendar-alt"
                    style="font-size: 23px;">
                    <span
                        v-if="badges.news"
                        class="badge-notification btn-pink animate__animated animate__infinite animate__pulse">{{ badges.news }}</span>
                </i>
                <span class="tabbar-label">{{ $t('news.title') }}</span>
            </f7-link>
            <f7-link tab-link route-tab-id="contact" href="./contact/" :icon-size="25">
                <i
                    class="fal fa-comments"
                    style="font-size: 25px;">
                    <span
                        v-if="badges.contact"
                        class="badge-notification btn-pink animate__animated animate__infinite animate__pulse">{{ badges.contact }}</span>
                </i>
                <span class="tabbar-label">{{ $t('contact.title') }}</span>
            </f7-link>
            <f7-link tab-link route-tab-id="other" href="./other/" :icon-size="23">
                <i
                    class="fal fa-home-heart"
                    style="font-size: 22px;">
                    <span
                        v-if="badges.other"
                        class="badge-notification btn-pink animate__animated animate__infinite animate__pulse">{{ badges.other }}</span>
                </i>
                <span class="tabbar-label">{{ $t('tools.title') }}</span>
            </f7-link>
        </f7-toolbar>

        <f7-tabs routable>
            <f7-tab id="home"></f7-tab>
            <f7-tab id="news"></f7-tab>
            <f7-tab id="contact"></f7-tab>
            <f7-tab id="other"></f7-tab>
        </f7-tabs>
    </f7-page>
</template>

<script>
    import $ from 'jquery';

    import { f7Page, f7Toolbar, f7Tabs, f7Tab, f7Link } from 'framework7-vue';

    export default {
        components: {
            f7Page,
            f7Toolbar,
            f7Tabs,
            f7Tab,
            f7Link,
        },
        data: () => ({
            badges: {
                home: null,
                news: null,
                contact: null,
                other: null
            }
        }),
        mounted() {
            // Prevent back for android
            $(document).on('backbutton', (e) => {
                if(typeof this.$f7router !== 'undefined' && this.$f7router) {
                    e.preventDefault();
                    this.$f7router.back();
                }
            });

            this.$bus.$on('navigateTo', (route, subroute) => {
                setTimeout(() => {
                    if(typeof this.$f7router !== 'undefined' && this.$f7router) {
                        this.$f7router.navigate('./' + route + '/');
                        subroute ? this.$f7router.navigate('/' + subroute + '/') : null;
                    }
                }, 600);
            });

            this.$notification_bus.$on('refreshBadges', () => {
                this.initBadges();
            });

            this.initBadges();
        },
        methods: {
            initBadges() {
                this.badges.home = this.$notification_bus.badges.home > 9 ? '+' : this.$notification_bus.badges.home;
                this.badges.news = this.$notification_bus.badges.news > 9 ? '+' : this.$notification_bus.badges.news;
                this.badges.contact = this.$notification_bus.badges.contact > 9 ? '+' : this.$notification_bus.badges.contact;
                this.badges.other = parseInt(this.$notification_bus.badges.documents) + parseInt(this.$notification_bus.badges.invoices) > 9 ? '+' : parseInt(this.$notification_bus.badges.documents) + parseInt(this.$notification_bus.badges.invoices);
            }
        }
    }
</script>