<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link back class="back" icon="fa fa-chevron-left" color="white" :icon-size="16">&nbsp;{{ $t('navigation.back') }}</f7-link>
            </f7-nav-left>
            <f7-nav-title class="grandhotel">{{ $t('details.title') }}</f7-nav-title>
        </f7-navbar>

        <content-loader v-if="loading" template="news"></content-loader>
        <div v-else-if="nursery" class="nursery-container">
            <div class="info text-center text-black mt-1">
                <h4>{{nursery.name}}</h4>
                <div v-if="nursery.phone" class="mt-3">
                    <i class="fa fa-phone text-pink"></i>&nbsp;
                    <span class="text-black">{{nursery.phone}}</span>
                </div>
                <div v-if="nursery.email" class="mt-1">
                    <i class="fa fa-envelope text-pink"></i>&nbsp;
                    <span @click="$openUrl('mailto:'+nursery.email, true)" class="text-black">{{nursery.email}}</span>
                </div>
            </div>
            <div class="contact text-center mt-3">
                <div v-if="nursery.phone" class="d-inline-block mt-2 mr-5">
                    <a @click="$openUrl('tel:'+nursery.phone.replace(/\s/g,''), true)" class="btn btn-circle btn-phone d-block external pointer">
                        <i class="fa fa-phone"></i>
                    </a>
                    <label class="text-black">{{ $t('details.call') }}</label>
                </div>
                <div class="d-inline-block mt-2">
                    <a @click="goToContact" class="btn btn-circle btn-contact d-block">
                        <i class="fa fa-retweet"></i>
                    </a>
                    <label class="text-black">{{ $t('details.contact') }}</label>
                </div>
            </div>
            <div v-if="nursery.address" class="address text-center text-black mt-3">
                <div>{{nursery.address}}</div>
                <div>{{nursery.zipcode}} {{nursery.city}}, {{nursery.country}}</div>
                <!-- <div id="map_canvas" class="map mt-1"></div> -->
            </div>
            <div class="mt-4 px-3">
                <template v-if="nursery.openingHours.length">
                    <h6 class="text-left text-info text-uppercase" style="font-size: 11px;">{{ $t('details.opening_hours') }}</h6>
                    <div class="p-2 rounded border border-info">
                        <div v-for="(open, i) in nursery.openingHours" :key="'open'+i" class="row my-1">
                            <div class="col-5 text-capitalize">
                                {{toHumanDay(open.day)}}
                            </div>
                            <div class="col-7 text-center">
                                {{toTime(open.started_at) + ' - ' + toTime(open.ended_at)}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="nursery.closedPeriods.length">
                    <h6 class="text-left text-danger text-uppercase mt-4" style="font-size: 11px;">{{ $t('details.holidays_included') }}</h6>
                    <div class="px-2 rounded border border-danger">
                        <div v-for="(close, i) in _sortBy(nursery.closedPeriods, 'started_at')" :key="'open'+i" class="row my-2">
                            <div class="col-12 text-capitalize-first text-danger">
                                {{close.name}}
                            </div>
                            <div class="col-6">
                                {{close.started_at | formatDate($t('date_format.days_months_years.simple'))}}
                            </div>
                            <div class="col-6">
                                {{close.ended_at | formatDate($t('date_format.days_months_years.simple'))}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="nursery.holidays.length">
                    <h6 class="text-left text-purple-title text-uppercase mt-4" style="font-size: 11px;">{{ $t('details.bank_holidays') }}</h6>
                    <div class="p-2 mb-3 rounded" style="border: 1px solid #6535E8;">
                        <div v-for="(holiday, i) in nursery.holidays" :key="'open'+i" class="row">
                            <div class="col text-capitalize">
                                {{holiday.name}}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </f7-page>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';

    import _sortBy from 'lodash/sortBy';

    import { f7Page, f7Navbar, f7NavLeft, f7NavTitle, f7Link } from 'framework7-vue';

    export default {
        components: {
            f7Page,
            f7Navbar,
            f7NavLeft,
            f7NavTitle,
            f7Link,
        },
        data() {
            return {
                nursery: this.$nursery,
                loading: false,
                moment: moment
            }
        },
        computed: {
            _sortBy() {
                return _sortBy;
            }
        },
        mounted() {
            this.getNurseries();
        },
        methods: {
            getNurseries() {
                this.loading = true;
                this.$http.get('/nurseries').then((response) => {
                    Vue.prototype.$nurseries = response.data;
                    if (response.data.length) {
                        Vue.prototype.$nursery = response.data.find((item) => item.id.toString() === this.$http_bus.nursery_id);
                        this.nursery = this.$nursery;
                    }
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            },

            goToContact() {
                this.$f7router.back();
                setTimeout(() => {
                    this.$f7router.navigate('/home/contact/');
                }, 800);
            },

            toTime(time) {
                return moment(time, 'HH:mm:ss').format(this.$t('date_format.hours_minutes'));
            },

            toHumanDay(day) {
                switch (day) {
                    case 'mon':
                        day = this.$t('calendar.days.monday');
                        break;
                    case 'tue':
                        day = this.$t('calendar.days.tuesday');
                        break;
                    case 'wed':
                        day = this.$t('calendar.days.wednesday');
                        break;
                    case 'thu':
                        day = this.$t('calendar.days.thursday');
                        break;
                    case 'fri':
                        day = this.$t('calendar.days.friday');
                        break;
                    case 'sat':
                        day = this.$t('calendar.days.saturday');
                        break;
                    case 'sun':
                        day = this.$t('calendar.days.sunday');
                        break;
                    default:
                        break;
                }
                return day;
            }
        }
    }
</script>


<style lang="scss" scoped>
    .nursery-container {
        padding: 10px;

        .btn-circle {
            display: block;
            width: 55px;
            height: 55px;
            border-radius: 50%;

            &.btn-phone {
                background-color: #A2F4C4;
                .fa {
                    color: #00C863;
                    font-size: 25px;
                    padding: 8px 0px;
                }
            }

            &.btn-contact {
                background-color: #E1D6F7;
                .fa {
                    color: #6738E0;
                    font-size: 25px;
                    padding: 8px 0px;
                }
            }
        }

        label {
            font-size: 14px;
        }

        .map {
            width: 100%;
            height: 220px;
            background: black;
        }
    }
</style>
