import Main from '@/views/Main';
import Home from '@/views/Home/Index';
import News from '@/views/News';
import Contact from '@/views/Contact';

import Other from '@/views/Other/Index';
import Kids from '@/views/Kids/Index';
import Photos from '@/views/Other/Photos';
import Invoices from '@/views/Other/Invoices';
import Payments from '@/views/Other/Payments';
import ContactInfo from '@/views/Other/ContactInfo';
import Documents from '@/views/Other/Documents';
import Team from '@/views/Other/Team';

export default [
    {
        path: '/home/',
        component: Main,
        tabs: [
            {
                path: '/',
                id: 'home',
                component: Home
            },
            {
                path: 'news/',
                id: 'news',
                component: News
            },
            {
                path: 'contact/',
                id: 'contact',
                component: Contact
            },
            {
                path: 'other/',
                id: 'other',
                component: Other
            }
        ]
    },
    {
        path: '/kids/',
        id: 'index',
        component: Kids
    },
    {
        path: '/photos/',
        id: 'photos',
        component: Photos
    },
    {
        path: '/invoices/',
        id: 'invoices',
        component: Invoices
    },
    {
        path: '/payments/',
        id: 'payments',
        component: Payments
    },
    {
        path: '/info/',
        id: 'info',
        component: ContactInfo
    },
    {
        path: '/documents/',
        id: 'documents',
        component: Documents
    },
    {
        path: '/team/',
        id: 'team',
        component: Team
    }
];
