<template>
    <div :style="style"></div>
</template>

<script>
    import NoAvatar from '@/assets/img/no-avatar.png';

    export default {
        props: {
            avatar: {},
            size: {},
            width: {},
            height: {},
            teddybear: {
                type: Boolean,
                default: true
            },
        },
        computed: {
            style() {
                let avatar = this.teddybear ? NoAvatar : NoAvatar;
                if (this.avatar) {
                    avatar = this.avatar;
                }
                return {
                    width: this.width ? this.width + 'px' : this.size + 'px',
                    height: this.height ? this.height + 'px' : this.size + 'px',
                    'background-image': 'url(' + avatar + ')',
                    'background-size': 'cover',
                    'background-position': 'center center',
                }
            }
        },
    }
</script>