<template>
    <f7-page name="contact">
        <f7-navbar>
            <f7-nav-left>&nbsp;</f7-nav-left>
            <f7-nav-title class="grandhotel">{{ $t('contact.title') }}</f7-nav-title>
            <f7-nav-right>&nbsp;</f7-nav-right>
        </f7-navbar>

        <div v-if="kids.length === 0 && !loading && !timelineLoading && !kidsLoading" class="no-access pt-5">
            <h2 class="grandhotel mt-5 text-pink text-center p-3">{{ $t('access.no_access') }}</h2>
        </div>
        <div v-else-if="has_access" class="contact-container h-100 position-relative">
            <div v-if="timelineLoading" class="infinite-scroll-preloader">
                <div class="preloader"></div>
            </div>

            <div id="timeline">
                <loader lg bubble v-if="loading" class="h-100"></loader>
                <f7-timeline v-else-if="filteredMessages.length === 0" class="m-0 p-1">
                    <f7-timeline-item :message="welcomeMessage.recieve"></f7-timeline-item>
                    <f7-timeline-item :message="welcomeMessage.sent" @inside-btn-clicked="openModalMessage(true)" showButton></f7-timeline-item>
                </f7-timeline>

                <f7-timeline v-else class="m-0 p-1">
                    <template v-for="(message, i) in filteredMessages">
                        <f7-timeline-item :message="message" :key="'message'+i"></f7-timeline-item>
                    </template>
                </f7-timeline>
            </div>

            <div class="actions pt-1">
                <div class="row h-50 m-0 pr-1 pl-1 align-items-center">
                    <div class="col-6 col-actions p-1">
                        <button @click="openModalAbsence" class="btn btn-sm btn-contact btn-pink">
                            <i class="fas fa-calendar-times mr-2"></i>{{ $t('contact.absence.title') }}
                        </button>
                    </div>
                    <div class="col-6 col-actions p-1">
                        <button @click="openModalOccasional" class="btn btn-sm btn-contact btn-primary">
                            <i class="fas fa-calendar-plus mr-2"></i>{{ $t('contact.booking.title') }}
                        </button>
                    </div>
                </div>
                <div class="row h-50 m-0 pr-1 pl-1">
                    <div class="col-6 col-actions p-1">
                        <button @click="openLate" class="btn btn-sm btn-contact btn-warning pt-2">
                            <loader v-if="late.loading"></loader>
                            <template v-else>
                                <img src="../assets/img/icons/late.svg" class="late mr-2" alt="Late" width="17px">{{ $t('contact.late.title') }}
                            </template>
                        </button>
                    </div>
                    <div class="col-6 col-actions p-1">
                        <button @click="openModalMessage(false)" class="btn btn-sm btn-contact btn-info">
                            <i class="fas fa-comment mr-2"></i>{{ $t('contact.message.title') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <check-access v-else v-model="has_access"></check-access>

        <custom-modal id="absenceModal" :title="$t('contact.absence.title_full')">
            <template slot="body">
                <div>
                    <div v-if="kids.length > 1" class="form-group mb-4">
                        <label for="selectKidAbsence">{{ $t('contact.absence.absence_for') }}</label>
                        <select v-model="selectedKid" class="form-control" id="selectKidAbsence">
                            <option v-for="(kid, i) in kids" :key="i" :value="kid.id">{{ kid.first_name }}</option>
                        </select>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-2">
                            <label>{{ $t('contact.absence.from') }}</label>
                        </div>
                        <div class="col-10">
                            <datepicker-btn v-model="absence.started_at" id="absence-started-at"></datepicker-btn>
                        </div>
                    </div>
                    <div class="row mt-2 align-items-center">
                        <div class="col-2">
                            <label>{{ $t('contact.absence.to') }}</label>
                        </div>
                        <div class="col-10">
                            <datepicker-btn v-model="absence.ended_at" id="absence-ended-at"></datepicker-btn>
                        </div>
                    </div>
                    <div v-if="absenceDuration > 1" class="mt-3">
                        <i class="fa fa-info-circle mr-1"/>
                        <span v-html="$t('contact.absence.absence_duration_tips', {count: absenceDuration})"/>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label>{{ $t('contact.absence.note') }}</label>
                            <textarea v-model="absence.content" class="form-control" rows="2" :placeholder="$t('contact.absence.note_placeholder')"></textarea>
                        </div>
                    </div>
                </div>
                <button @click="saveAbsence" class="btn btn-pink btn-block btn-modal">
                    <loader v-if="absence.loading"></loader>
                    <template v-else>{{ $t('contact.absence.send') }}</template>
                </button>
            </template>
        </custom-modal>

        <custom-modal id="occasionalModal" :title="$t('contact.booking.title_full')">
            <template slot="body">
                <div>
                    <div v-if="kids.length > 1" class="form-group mb-4">
                        <label for="selectKidOccasional">{{ $t('contact.booking.booking_for') }}</label>
                        <select v-model="selectedKid" class="form-control" id="selectKidOccasional">
                            <option v-for="(kid, i) in kids" :key="i" :value="kid.id">{{ kid.first_name }}</option>
                        </select>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-2">
                            <label>{{ $t('contact.booking.from') }}</label>
                        </div>
                        <div class="col-10">
                            <datepicker-btn v-model="occasional.started_at" id="occasional-started-at"></datepicker-btn>
                        </div>
                    </div>
                    <div class="row mt-2 align-items-center">
                        <div class="col-2">
                            <label>{{ $t('contact.booking.to') }}</label>
                        </div>
                        <div class="col-10">
                            <datepicker-btn v-model="occasional.ended_at" id="occasional-ended-at"></datepicker-btn>
                        </div>
                    </div>
                </div>
                <button @click="saveOccasional" class="btn btn-primary btn-block btn-modal">
                    <loader v-if="occasional.loading"></loader>
                    <template v-else>{{ $t('contact.booking.send') }}</template>
                </button>
            </template>
        </custom-modal>

        <custom-modal id="messageModal" :title="$t('contact.message.title_full')">
            <template slot="body">
                <div>
                    <textarea v-model="newMessage.content" id="chat-input" class="form-control" rows="3" :placeholder="$t('contact.message.message_placeholder')"></textarea>
                </div>
                <div class="mt-2 mx-1">
                    <label for="files-upload" class="btn btn-link btn-block text-left px-0 py-1 mb-0">
                        <i class="fad fa-file-plus mr-2"/>{{ $t('contact.message.add_documents') }}
                    </label>
                    <input type="file" id="files-upload" class="d-none" onclick="this.value=null;" multiple @change="addFiles($event)"/>
                    <div v-for="(file, i) in newMessage.attachedFiles" :key="'attachedFile' + i" class="d-flex align-items-center border-bottom mt-1 mb-2">
                        <div>
                            <i v-if="file.uploading" class="fa fa-arrow-circle-up small animate__animated animate__rubberBand animate__slow animate__infinite mr-1" />
                            <i v-else-if="file.uploaded" class="fa fa-check small animate__animated animate__swing mr-1" />
                            <i v-else class="fa fa-times-circle small text-danger animate__animated animate__headShake mr-1" />
                            {{ file.name }}
                        </div>
                        <button class="btn btn-link text-dark text-left p-0 ml-auto w-auto" @click="removeFile(file)">
                            <i class="fad fa-times-circle"/>
                        </button>
                    </div>
                </div>
                <div class="custom-control custom-switch mt-4 mx-1">
                    <input v-model="newMessage.manager_only" type="checkbox" id="sendToManager" class="custom-control-input"/>
                    <label for="sendToManager" class="custom-control-label text-dark">{{ $t('contact.message.send_to_manager') }}</label>
                </div>
                <button v-if="filesUploading" class="btn btn-info btn-block btn-modal disabled" disabled>
                    <i class="fa fa-arrow-circle-up animate__animated animate__rubberBand animate__slow animate__infinite mr-2" />{{ $t('contact.message.send') }}
                </button>
                <button v-else @click="saveMessage" class="btn btn-info btn-block btn-modal">
                    <loader v-if="newMessage.loading"></loader>
                    <template v-else>{{ $t('contact.message.send') }}</template>
                </button>
            </template>
        </custom-modal>

        <f7-actions :opened="showLateMenu" @actions:closed="showLateMenu = false">
            <f7-actions-group>
                <f7-actions-button @click="saveLate(60)">{{ $t('contact.late.one_and_more') }}</f7-actions-button>
                <f7-actions-button @click="saveLate(45)">{{ $t('contact.late.fourty_five_minutes') }}</f7-actions-button>
                <f7-actions-button @click="saveLate(30)">{{ $t('contact.late.thirty_minutes') }}</f7-actions-button>
                <f7-actions-button @click="saveLate(10)">{{ $t('contact.late.ten_minutes') }}</f7-actions-button>
            </f7-actions-group>
            <f7-actions-group>
                <f7-actions-button @click="showLateMenu = false" color="red" bold>{{ $t('contact.late.cancel') }}</f7-actions-button>
            </f7-actions-group>
        </f7-actions>
    </f7-page>
</template>

<script>
    import moment from 'moment';
    import $ from 'jquery';

    import { upload } from '@/libs/usercontent.js';

    import _orderBy from 'lodash/orderBy';
    import _concat from 'lodash/concat';

    import CustomModal from '@/components/CustomModal';
    import DatepickerBtn from '@/components/DatepickerBtn';
    import F7Timeline from '@/components/f7-timeline/f7Timeline';
    import F7TimelineItem from '@/components/f7-timeline/f7TimelineItem';

    import {
        f7Actions,
        f7ActionsButton,
        f7ActionsGroup,
        f7Navbar,
        f7NavLeft,
        f7NavRight,
        f7NavTitle,
        f7Page
    } from 'framework7-vue';

    export default {
        components: {
            CustomModal,
            DatepickerBtn,
            F7Timeline,
            F7TimelineItem,
            f7Page,
            f7Navbar,
            f7NavLeft,
            f7NavTitle,
            f7NavRight,
            f7Actions,
            f7ActionsGroup,
            f7ActionsButton
        },
        data() {
            return {
                nursery: this.$nursery,
                messages: [],
                kids: [{}],
                selectedKid: null,
                loading: false,
                timelineLoading: false,
                kidsLoading: false,
                showLateMenu: false,
                absence: {
                    started_at: moment(),
                    ended_at: moment(),
                    content: null,
                    kid: null,
                    loading: false
                },
                occasional: {
                    started_at: moment(),
                    ended_at: moment(),
                    kid: null,
                    loading: false
                },
                newMessage: {
                    content: null,
                    manager_only: false,
                    attachedFiles: [],
                    loading: false
                },
                late: {
                    loading: false
                },
                now: null,
                last_loaded_page: 0,
                last_page: null,
                has_access: this.$http_bus.has_access,
                welcomeMessage: {
                    recieve: {
                        created_at: moment().unix(),
                        sent_by: 'nursery',
                        content: this.$t('contact.welcome_message'), // Bienvenue sur notre outil d\'échange avec les familles :)
                        type: 'message'
                    },
                    sent: {
                        created_at: moment().unix(),
                        sent_by: 'family',
                        content: this.$t('contact.send_your_first_message'), // Envoyez-nous votre premier message !
                        type: 'message'
                    }
                },
            }
        },
        computed: {
            filteredMessages() {
                return _orderBy(this.messages, 'created_at', 'asc');
            },
            filesUploading() {
                return this.newMessage.attachedFiles.length && this.newMessage.attachedFiles.some((file) => {
                    return file.uploading;
                });
            },
            absenceDuration() {
                if (this.absence.started_at && this.absence.ended_at) {
                    return moment(this.absence.ended_at).diff(this.absence.started_at, 'days') + 1;
                }

                return 0;
            }
        },
        mounted() {
            setTimeout(() => {
                this.$bus.$on('resume', () => {
                    localStorage.getItem("connected") === 'true' ? this.getContact() : null;
                });
            }, 400);

            this.$notification_bus.resetLocalBadge('new-message-v1');

            this.getContact();
            this.getKids();
            this.initEcho();

            this.now = moment().format();

            let self = this;
            $('#timeline').on('scroll', function () {
                if (!self.loading && ($(this).scrollTop() <= 0)) {
                    self.getContact(true);
                }
            });
        },
        destroyed() {
            this.$bus.$off('resume');
        },
        methods: {
            getContact(newPage) {
                if (this.timelineLoading) {
                    return;
                }

                if (newPage && this.last_loaded_page === this.last_page) {
                    return;
                }

                newPage ? this.timelineLoading = true : this.loading = true;
                this.$http.get('/messages', {
                    params: {
                        page: this.last_loaded_page + 1
                    }
                }).then((response) => {
                    let nursery_data = response.data.data.filter((item) => item.nursery_id.toString() === this.$http_bus.nursery_id);
                    if (response.current_page === 1) {
                        this.messages = nursery_data;
                    } else {
                        let sizeBefore = $("#timeline") && $("#timeline")[0] ? $("#timeline")[0].scrollHeight :
                            0;
                        this.messages = _concat(this.messages, nursery_data);

                        // KEEP SCROLL IN POSITION
                        setTimeout(() => {
                            if ($("#timeline") && $("#timeline")[0]) {
                                $("#timeline")[0].scrollTop = $("#timeline")[0].scrollHeight - (
                                    sizeBefore + 50);
                            }
                        }, 200);
                    }
                    this.last_loaded_page = response.data.current_page;
                    this.last_page = response.data.last_page;

                    if (newPage) {
                        this.timelineLoading = false;
                    } else {
                        this.loading = false;
                        this.resetScroll(100);
                    }
                }).catch(() => {
                    this.loading = false;
                    this.timelineLoading = false;
                });
            },

            getKids() {
                this.kidsLoading = true;
                this.$http.get('/kids').then((response) => {
                    this.kids = response.data.filter((item) => item.nursery_id.toString() === this.$http_bus.nursery_id);
                    this.kids.length ? this.selectedKid = this.kids[0].id : null;
                    setTimeout(() => {
                        this.kidsLoading = false;
                    }, 300);
                }).catch(() => {
                    this.kidsLoading = false;
                });
            },

            initEcho() {
                if (this.$http_bus.user_id !== null) {
                    window.Echo.private('App.Models.Family.User.' + this.$http_bus.user_id).listen('MessageSent', (
                        message) => {
                        if (message) {
                            this.messages.unshift(message);
                            this.resetScroll();
                        }
                    });
                }
            },

            openModalAbsence() {
                let opening = this.nursery && this.nursery.openingHours ? this.$getOpening(this.nursery.openingHours) : '07:00';
                let closing = this.nursery && this.nursery.openingHours ? this.$getClosing(this.nursery.openingHours) : '18:00';

                this.absence.started_at = moment(opening, 'HH:mm').format();
                this.absence.ended_at = moment(closing, 'HH:mm').format();
                this.absence.content = null;
                this.absence.kid = null;
                $('#absenceModal').modal({
                    focus: false,
                });
            },

            saveAbsence() {
                if (this.absence.loading) {
                    return;
                }

                let start = this.unixDate(this.absence.started_at);
                let end = this.unixDate(this.absence.ended_at);

                this.absence.loading = true;

                this.$http.post('/messages', {
                    type: 'absence',
                    content: this.absence.content,
                    started_at: start,
                    ended_at: end,
                    kid_id: this.selectedKid
                }).then((response) => {
                    this.messages.unshift(response.data);
                    $('#absenceModal').modal('hide');
                    this.absence.loading = false;
                    this.resetScroll();
                }).catch(() => {
                    this.absence.loading = false;
                });
            },

            openModalOccasional() {
                let opening = this.nursery && this.nursery.openingHours ? this.$getOpening(this.nursery.openingHours) : '07:00';
                let closing = this.nursery && this.nursery.openingHours ? this.$getClosing(this.nursery.openingHours) : '18:00';

                this.occasional.started_at = moment(opening, 'HH:mm').format();
                this.occasional.ended_at = moment(closing, 'HH:mm').format();
                this.occasional.kid = null;
                $('#occasionalModal').modal({
                    focus: false,
                });
            },

            saveOccasional() {
                if (this.occasional.loading) {
                    return;
                }

                let start = this.unixDate(this.occasional.started_at);
                let end = this.unixDate(this.occasional.ended_at);

                this.occasional.loading = true;

                this.$http.post('/messages', {
                    type: 'occasional',
                    started_at: start,
                    ended_at: end,
                    kid_id: this.selectedKid
                }).then((response) => {
                    this.messages.unshift(response.data);
                    $('#occasionalModal').modal('hide');
                    this.occasional.loading = false;
                    this.resetScroll();
                }).catch(() => {
                    this.occasional.loading = false;
                });
            },

            openModalMessage(random) {
                if (random) {
                    let rand = Math.random() * 10;
                    let randMessage = null;
                    if (rand > 8) {
                        randMessage = this.$t('contact.message.random_message_1');
                    } else if (rand > 6) {
                        randMessage = this.$t('contact.message.random_message_2');
                    } else if (rand > 4) {
                        randMessage = this.$t('contact.message.random_message_3');
                    } else if (rand > 2) {
                        randMessage = this.$t('contact.message.random_message_4');
                    } else {
                        randMessage = this.$t('contact.message.random_message_5');
                    }
                    this.newMessage.content = randMessage;
                } else {
                    this.newMessage.content = null;
                }
                
                this.newMessage.manager_only = false;
                this.newMessage.attachedFiles = [];
                $('#messageModal').modal();
                setTimeout(() => {
                    $('#chat-input').focus();
                    $('#chat-input').trigger("click");
                }, 600);
            },

            saveMessage() {
                if (this.newMessage.loading) {
                    return;
                }

                this.newMessage.loading = true;

                const documents = this.newMessage.attachedFiles.filter((doc) => doc.uploaded);

                this.$http.post('/messages', {
                    type: 'message',
                    content: this.newMessage.content,
                    manager_only: this.newMessage.manager_only,
                    documents: documents.length ? documents : undefined,
                }).then((response) => {
                    this.messages.unshift(response.data);
                    $('#messageModal').modal('hide');
                    this.newMessage.content = null;
                    this.newMessage.loading = false;
                    this.resetScroll();
                }).catch(() => {
                    this.newMessage.loading = false;
                });
            },

            openLate() {
                if (this.late.loading) {
                    return;
                }
                this.showLateMenu = true;
            },

            saveLate(time) {
                if (this.late.loading) {
                    return;
                }

                this.showLateMenu = false;
                this.late.loading = true;
                this.$http.post('/messages', {
                    type: 'late',
                    late: time
                }).then((response) => {
                    this.messages.unshift(response.data);
                    this.late.loading = false;
                    this.resetScroll();
                }).catch(() => {
                    this.late.loading = false;
                });
            },

            addFiles($event) {
                const filesList = $event.target.files;
                [...filesList].forEach((doc) => {
                    const fileObj = {
                        name: doc.name ? doc.name : "Document",
                        file: {
                            name: doc.name ? doc.name : "Document",
                            key: null,
                            hash: null,
                        },
                        uploading: true,
                        uploaded: false,
                    }
                    this.newMessage.attachedFiles.push(fileObj);

                    upload(doc).then((res) => {
                        fileObj.file.key = res.key;
                        fileObj.file.hash = res.hash;
                        fileObj.uploading = false;
                        fileObj.uploaded = true;
                    }).catch(() => {
                        fileObj.uploading = false;
                        fileObj.uploaded = false;
                    });
                });
            },

            removeFile(file) {
                const index = this.newMessage.attachedFiles.indexOf(file);
                this.newMessage.attachedFiles.splice(index, 1);
            },

            resetScroll(time) {
                if ($("#timeline")) {
                    let delay = time ? time : 600;
                    setTimeout(() => {
                        $("#timeline").animate({
                            scrollTop: $("#timeline")[0] ? $("#timeline")[0].scrollHeight : 0
                        }, delay);
                    }, 300);
                }
            },

            unixDate(date) {
                return moment(date).unix();
            }
        }
    }
</script>

<style lang="scss" scoped>
    #timeline {
        height: calc(100% - 150px);
        width: 100%;
        background: #FFF;
        overflow: scroll;
    }

    .actions {
        position: absolute;
        bottom: 0;
        height: 150px;
        width: 100%;
        background: rgba(243, 243, 243, 0.4);
        z-index: 10;

        .col-actions {
            height: 80%;

            .btn-contact {
                width: 100%;
                height: 100%;
                min-height: 45px;
                font-weight: 500;
                font-size: 17px;
                box-shadow: 0px 2px 3px 1px #c1c1c1;

                .fa, .fas, .far, .fal {
                    font-size: 20px;
                }

                .late {
                    margin-right: 3px;
                    margin-top: -3px;
                }

                &:active {
                    color: white;
                }
            }
        }
    }
</style>

<style lang="scss">
    .no-access {
        position: absolute;
        height: 100%;
        width: 100%;
        color: white;
        background: rgba(255, 255, 255, 0.9);
        z-index: 1;
    }

    .actions-group {
        border-radius: 5px;
        background: white;

        .actions-button:not(.color-red) {
            color: #FF268E;
        }
    }
</style>