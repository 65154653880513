<template>
    <video
        :src="`${url}`"
        width="100%"
        height="auto"
        preload="metadata"
        controls
    >
        <source :src="`${url}`" :type="extensionType" />
        {{ $t('daily_reports.videos.video_not_supported') }}
    </video>
</template>

<script>
    export default {
        props: {
            url: {
                type: String,
                required: true,
            },
            extensionType: {
                type: String,
                default: 'video/mp4',
            },
        },
    };
</script>