import Vue from 'vue';
import moment from 'moment';
import axios from 'axios';

let HttpBus = new Vue();

HttpBus.api_url = window.API_URL + '/v1';
HttpBus.api_token = localStorage.getItem("api_token");
HttpBus.user_id = localStorage.getItem("user_id") !== 'null' ? localStorage.getItem("user_id") : null;
HttpBus.nursery_id = localStorage.getItem("nursery_id") !== 'null' ? localStorage.getItem("nursery_id") : null;
HttpBus.connected = localStorage.getItem("connected") === 'true';
HttpBus.nursery_timezone = localStorage.getItem("nursery_timezone");
HttpBus.has_access = localStorage.getItem("has_access") === 'true';

HttpBus.initApi = (api) => {
    HttpBus.user_id = api.id.toString();
    HttpBus.api_token = api.token;
    HttpBus.nursery_id = api.nursery_id ? api.nursery_id.toString() : null;
    HttpBus.nursery_timezone = api.timezone;
    api.timezone ? moment.tz.setDefault(api.timezone) : null;

    if(api.id !== null) {
        localStorage.setItem("user_id", api.id);
    } else {
        localStorage.setItem("user_id", null);
    }

    if (api.nursery_id !== null) {
        localStorage.setItem("nursery_id", api.nursery_id);
        HttpBus.has_access = true;
        localStorage.setItem("has_access", true);
    } else {
        localStorage.setItem("nursery_id", null);
    }

    localStorage.setItem("api_token", api.token);
    localStorage.setItem("connected", true);
    localStorage.setItem("nursery_timezone", api.timezone);
}

HttpBus.initNursery = (nursery_id) => {
    if(nursery_id !== null) {
        localStorage.setItem("nursery_id", nursery_id);
        HttpBus.nursery_id = nursery_id.toString();
        HttpBus.has_access = true;
        localStorage.setItem("has_access", true);
    }
}

HttpBus.initUser = (user) => {
    Vue.prototype.$user = user;

    HttpBus.user_id = user.id.toString();
    HttpBus.nursery_id = user.nursery_id.toString();
    HttpBus.nursery_timezone = user.timezone;
    user.timezone ? moment.tz.setDefault(user.timezone) : null;

    localStorage.setItem("user_id", user.id);
    localStorage.setItem("nursery_id", user.nursery_id);
    localStorage.setItem("nursery_timezone", user.timezone);
}

HttpBus.resetApi = () => {
    HttpBus.user_id = null;
    HttpBus.api_token = null;
    HttpBus.nursery_id = null;
    HttpBus.connected = false;

    localStorage.removeItem("user_id");
    localStorage.removeItem("api_token");
    localStorage.removeItem("nursery_id");
    localStorage.removeItem("connected");
    localStorage.removeItem("has_access");
}

Object.defineProperties(Vue.prototype, {
    $http_bus: {
        get: () => {
            return HttpBus;
        }
    },
    $http: {
        get: () => {
            let req = axios.create({
                baseURL: HttpBus.api_url,
                timeout: 60000,
                headers: {
                    Authorization: 'Bearer ' + HttpBus.api_token
                }
            });

            req.interceptors.response.use((response) => {
                return response;
            }, (e) => {
                HttpBus.$handleError(e);
                return Promise.reject(e);
            });

            return req;
        }
    },
    $http_base: {
        get: () => {
            let req = axios.create({
                baseURL: window.API_URL,
                timeout: 60000,
                headers: {
                    Authorization: 'Bearer ' + HttpBus.api_token
                }
            });

            req.interceptors.response.use((response) => {
                return response;
            }, (e) => {
                HttpBus.$handleError(e);
                return Promise.reject(e);
            });

            return req;
        }
    }
});