<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link back class="back" icon="fa fa-chevron-left" color="white" :icon-size="16">&nbsp;{{ $t('navigation.back') }}</f7-link>
            </f7-nav-left>
            <f7-nav-title class="grandhotel">{{ $t('kids.title') }}</f7-nav-title>
        </f7-navbar>

        <div class="p-2">
            <content-loader v-if="loading"></content-loader>
            <div v-else-if="!kids.length" class="mt-5">
                <h2 class="text-pink text-center grandhotel p-3">{{ $t('kids.empty_data') }}</h2>
                <div v-if="showJoke" class="small text-center text-purple-title">{{ $t('kids.empty_data_joke') }}</div>
            </div>
            <div v-else v-for="(kid, i) in kids" :key="'kid'+i" class="card card-3d p-3 mb-3">
                <div class="row">
                    <div class="col-3 align-self-center">
                        <avatar :avatar="kid.avatar_url" :size="65" class="rounded-circle"></avatar>
                    </div>
                    <div class="col-9 align-self-center">
                        <h3 class="grandhotel text-purple-title mb-0">{{ kid.first_name + ' ' + kid.last_name | capitalize }}</h3>
                        <div class="text-secondary">
                            <strong>{{ kid.birthdate | formatDate($t('date_format.days_months_years.simple')) }}</strong>
                        </div>
                        <div v-if="kid.attendance_code" class="text-secondary mt-2">
                            {{ $t('kids.attendance_code') + ' ' + kid.attendance_code }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </f7-page>
</template>

<script>
    import { f7Page, f7Navbar, f7NavLeft, f7NavTitle, f7Link } from 'framework7-vue';

    export default {
        components: {
            f7Page,
            f7Navbar,
            f7NavLeft,
            f7NavTitle,
            f7Link,
        },
        data() {
            return {
                kids: [],
                loading: false
            }
        },
        computed: {
            showJoke() {
                return this.$shouldDisplayJoke(this.$nursery.id);
            }
        },
        mounted() {
            this.getKids();
        },
        methods: {
            getKids() {
                this.loading = true;

                this.$http.get('/kids').then((response) => {
                    this.loading = false;
                    this.kids = response.data;
                }).catch(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>