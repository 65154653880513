<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link
                    back
                    class="back"
                    icon="fa fa-chevron-left"
                    color="white"
                    :icon-size="16"
                >&nbsp;{{ $t('navigation.back') }}</f7-link>
            </f7-nav-left>
            <f7-nav-title class="grandhotel">{{ $t('documents.title') }}</f7-nav-title>
            <f7-subnavbar>
                <f7-segmented raised>
                    <f7-button
                        tab-link="#tabNurseryDocuments"
                        tab-link-active
                    >{{ $t('documents.general_title') }}</f7-button>
                    <f7-button tab-link="#tabUserDocuments">{{ $t('documents.family_title') }}</f7-button>
                </f7-segmented>
            </f7-subnavbar>
        </f7-navbar>

        <div class="my-2 px-3">
            <div class="d-inline-flex w-auto align-items-center rounded border small px-2">
                <i class="fa fa-sort-alt mr-2" />
                <select v-model="documentsOrderBy">
                    <option :value="'date'">{{ $t('documents.by_date') }}</option>
                    <option :value="'name'">{{ $t('documents.by_name') }}</option>
                </select>
            </div>
        </div>

        <f7-tabs>
            <f7-tab
                id="tabNurseryDocuments"
                tab-active
            >
                <div class="documents">
                    <content-loader
                        v-if="loading"
                        template="list"
                    />
                    <div v-else-if="nurseryFiles.length === 0 && nurseryLinks.length === 0">
                        <h2
                            class="grandhotel text-purple-title text-center mt-5 p-3"
                        >{{ $t('documents.empty_data') }}</h2>
                    </div>
                    <div v-else>
                        <f7-list
                            media-list
                            class="m-0"
                        >
                            <template v-if="nurseryFiles.length">
                                <f7-list-item
                                    :title="$t('documents.files_title')"
                                    divider
                                ></f7-list-item>
                                <f7-list-item
                                    v-for="(file, i) in nurseryFiles"
                                    :key="'file' + i"
                                    external
                                    after
                                    @click="$openUrl(file.file_url)"
                                    :title="file.name"
                                    class="text-purple-title pt-1"
                                >
                                    <div slot="media">
                                        <i class="fa fa-file"></i>
                                    </div>
                                    <i class="far fa-angle-right arrow-right"></i>
                                </f7-list-item>
                            </template>
                            <template v-if="nurseryLinks.length">
                                <f7-list-item
                                    :title="$t('documents.links_title')"
                                    divider
                                ></f7-list-item>
                                <f7-list-item
                                    v-for="(link, i) in nurseryLinks"
                                    :key="'link' + i"
                                    external
                                    after
                                    @click="$openUrl(link.link)"
                                    :title="link.name"
                                    class="text-pink pt-1"
                                >
                                    <div slot="media">
                                        <i class="fa fa-globe"></i>
                                    </div>
                                    <i class="far fa-angle-right arrow-right"></i>
                                </f7-list-item>
                            </template>
                        </f7-list>
                    </div>
                </div>
            </f7-tab>

            <f7-tab id="tabUserDocuments">
                <div class="documents">
                    <content-loader
                        v-if="loading"
                        template="list"
                    ></content-loader>
                    <div v-else-if="otherFiles.length === 0 && otherLinks.length === 0">
                        <h2
                            class="grandhotel text-purple-title text-center mt-5 p-3"
                        >{{ $t('documents.empty_data') }}</h2>
                    </div>
                    <div v-else>
                        <f7-list
                            media-list
                            class="m-0"
                        >
                            <template v-if="otherFiles.length">
                                <f7-list-item
                                    :title="$t('documents.files_title')"
                                    divider
                                ></f7-list-item>
                                <f7-list-item
                                    v-for="(file, i) in otherFiles"
                                    :key="'file' + i"
                                    external
                                    after
                                    @click="$openUrl(file.file_url)"
                                    :title="file.name"
                                    class="text-purple-title pt-1"
                                >
                                    <template v-if="file.kids && file.kids.length">
                                        <div
                                            v-for="(kid, i) in file.kids"
                                            :key="'fileKid' + i"
                                            class="item-subtitle"
                                        >{{ kid.first_name + ' ' + kid.last_name }}</div>
                                    </template>
                                    <template v-if="file.family_members && file.family_members.length">
                                        <div
                                            v-for="(member, i) in file.family_members"
                                            :key="'fileMember' + i"
                                            class="item-subtitle"
                                        >{{ member.first_name + ' ' + member.last_name }}</div>
                                    </template>

                                    <div slot="media">
                                        <i class="fa fa-file"></i>
                                    </div>
                                    <i class="far fa-angle-right arrow-right"></i>
                                </f7-list-item>
                            </template>
                            <template v-if="otherLinks.length">
                                <f7-list-item
                                    :title="$t('documents.links_title')"
                                    divider
                                ></f7-list-item>
                                <f7-list-item
                                    v-for="(link, i) in otherLinks"
                                    :key="'link' + i"
                                    external
                                    after
                                    @click="$openUrl(link.link)"
                                    :title="link.name"
                                    class="text-pink pt-1"
                                >
                                    <template v-if="link.kids && link.kids.length">
                                        <div
                                            v-for="(kid, i) in link.kids"
                                            :key="'linkKid' + i"
                                            class="item-subtitle"
                                        >{{ kid.first_name + ' ' + kid.last_name }}</div>
                                    </template>
                                    <template v-if="link.family_members && link.family_members.length">
                                        <div
                                            v-for="(member, i) in link.family_members"
                                            :key="'linkMember' + i"
                                            class="item-subtitle"
                                        >{{ member.first_name + ' ' + member.last_name }}</div>
                                    </template>

                                    <div slot="media">
                                        <i class="fa fa-globe"></i>
                                    </div>
                                    <i class="far fa-angle-right arrow-right"></i>
                                </f7-list-item>
                            </template>
                        </f7-list>
                    </div>
                </div>
            </f7-tab>
        </f7-tabs>
    </f7-page>
</template>

<script>
    import {
        f7Page,
        f7Navbar,
        f7NavLeft,
        f7NavTitle,
        f7Link,
        f7List,
        f7ListItem,
        f7Subnavbar,
        f7Segmented,
        f7Button,
        f7Tabs,
        f7Tab,
    } from 'framework7-vue';

    import _orderBy from 'lodash/orderBy';

    export default {
        components: {
            f7Page,
            f7Navbar,
            f7NavLeft,
            f7NavTitle,
            f7Link,
            f7List,
            f7ListItem,
            f7Subnavbar,
            f7Segmented,
            f7Button,
            f7Tabs,
            f7Tab,
        },
        data() {
            return {
                nurseryDocuments: [],
                otherDocuments: [],
                loading: false,
                documentsOrderBy: window.localStorage.getItem('documentsOrderBy') || 'date',
            };
        },
        computed: {
            orderedNurseryDocuments() {
                if (this.documentsOrderBy === 'name') {
                    return _orderBy(this.nurseryDocuments, 'name', 'asc');
                }
                return this.nurseryDocuments;
            },
            orderedOtherDocuments() {
                if (this.documentsOrderBy === 'name') {
                    return _orderBy(this.otherDocuments, 'name', 'asc');
                }
                return this.otherDocuments;
            },

            nurseryFiles() {
                return this.orderedNurseryDocuments.filter(
                    (doc) => doc.link === null && doc.file_url
                );
            },
            nurseryLinks() {
                return this.orderedNurseryDocuments.filter((doc) => doc.link !== null);
            },

            otherFiles() {
                return this.orderedOtherDocuments.filter(
                    (doc) => doc.link === null && doc.file_url
                );
            },
            otherLinks() {
                return this.orderedOtherDocuments.filter((doc) => doc.link !== null);
            },
        },
        watch: {
            documentsOrderBy(val) {
                window.localStorage.setItem('documentsOrderBy', val);
            }
        },
        mounted() {
            setTimeout(() => {
                this.$bus.$on('resume', () => {
                    localStorage.getItem('connected') === 'true'
                        ? this.getDocuments()
                        : null;
                });
            }, 400);

            this.$notification_bus.resetLocalBadge('new-document-v1');

            this.getDocuments();
        },
        destroyed() {
            this.$bus.$off('resume');
        },
        methods: {
            getDocuments() {
                this.loading = true;
                this.$http
                    .get('/documents')
                    .then((response) => {
                        this.otherDocuments = [];
                        this.nurseryDocuments = [];
                        response.data.forEach((doc) => {
                            if (
                                (doc.kids && doc.kids.length) ||
                                (doc.family_members && doc.family_members.length)
                            ) {
                                this.otherDocuments.push(doc);
                            } else {
                                this.nurseryDocuments.push(doc);
                            }
                        });
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>

<style lang="scss">
    .documents {
        .item-title {
            margin-top: -2px;
        }

        .item-subtitle {
            color: rgb(119, 119, 119) !important;
            font-size: 13px !important;
        }

        .item-media {
            .fa {
                font-size: 20px;
            }
        }

        .arrow-right {
            font-size: 25px;
            color: rgb(170, 170, 170);
            position: absolute;
            right: 9px;
            top: 8px;
        }
    }
</style>