<template>
    <!-- MESSAGE -->
    <div v-if="message.type === 'message'" class="timeline-item chat-message">
        <div class="timeline-item-date">
            {{displayDate(message.created_at)}}
        </div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content">
            <f7-messages>
                <f7-message v-if="message.sent_by === 'family'" class="mr-0" type="sent" tail>
                    <div
                        v-if="message.manager_only"
                        class="w-100 text-white small mr-auto border-bottom mb-2 pb-1"
                    >
                        <i class="far fa-lock-alt mr-1" />{{ $t('contact.message.management_only') }}
                    </div>
                    <span v-html="anchorme(message.content)" />
                    <button v-if="showButton" @click="$emit('inside-btn-clicked')" class="btn btn-contact btn-info btn-inverse btn-block mt-2">
                        <i class="fa fa-comment fa-flip-horizontal mr-2"></i>{{ $t('contact.click_here') }}
                    </button>
                    <div
                        v-if="message.documents && message.documents.length"
                        class="text-white border-top mt-2"
                    >
                        <div
                            v-for="(document, i) in message.documents"
                            :key="'document' + i"
                            class="my-2 small"
                        >
                            <a
                                :href="document.file_url"
                                style="text-decoration: none;"
                            >
                                {{ document.name }}
                            </a>
                        </div>
                    </div>
                </f7-message>
                <f7-message v-else class="ml-0" type="received" tail>
                    <div
                        v-if="message.manager_only"
                        class="w-100 text-white small mr-auto border-bottom mb-2 pb-1"
                    >
                        <i class="far fa-lock-alt mr-1" />{{ $t('contact.message.management_only') }}
                    </div>
                    <span v-html="anchorme(message.content)" />
                </f7-message>
            </f7-messages>
        </div>
    </div>

    <!-- OCCASIONAL -->
    <div v-else-if="message.type === 'occasional'" class="timeline-item occasional">
        <div class="timeline-item-date">
            {{displayDate(message.created_at)}}
        </div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content">
            <div class="timeline-item-inner">
                {{ $t('contact.booking.booking_for') }} {{message.kid_first_name | capitalize}}
                <div class="text-primary small">
                    {{message.started_at | formatDate($t('date_format.days_months_years.simple'))}} - {{message.started_at | simpleTime($t('date_format.hours_minutes'))}} {{ $t('contact.to') }} {{message.ended_at | simpleTime($t('date_format.hours_minutes'))}}
                </div>
                <div
                    v-if="message.state === 'accepted'"
                    class="text-success small">
                    <i class="far fa-check-circle mr-1"/>{{ $t('contact.booking.booking_accepted') }}
                </div>
                <div
                    v-else-if="message.state === 'rejected'"
                    class="text-danger small">
                    <i class="far fa-times-circle mr-1"/>{{ $t('contact.booking.booking_refused') }}
                </div>
                <div
                    v-else
                    class="text-primary small">
                    <i class="far fa-question-circle mr-1"/>{{ $t('contact.booking.booking_pending') }}
                </div>
            </div>
        </div>
    </div>

    <!-- ABSENCE -->
    <div v-else-if="message.type === 'absence'" class="timeline-item absence">
        <div class="timeline-item-date">
            {{displayDate(message.created_at)}}
        </div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content">
            <div class="timeline-item-inner">
                {{ $t('contact.absence.absence_for') }} {{message.kid_first_name | capitalize}}
                <div class="text-danger small">
                    <template v-if="sameDay(message)">
                        {{message.started_at | formatDate($t('date_format.days_months_years.simple'))}} - {{message.started_at | simpleTime($t('date_format.hours_minutes'))}} {{ $t('contact.to') }} {{message.ended_at | simpleTime($t('date_format.hours_minutes'))}}
                        <br>{{message.content}}
                    </template>
                    <template v-else>
                        {{ $t('contact.absence.from') }} {{message.started_at | formatDate($t('date_format.days_months_years.simple'))}} {{ $t('contact.at') }} {{message.started_at | simpleTime($t('date_format.hours_minutes'))}}
                        <br>{{ $t('contact.absence.to') }} {{message.ended_at | formatDate($t('date_format.days_months_years.simple'))}} {{ $t('contact.at') }} {{message.ended_at | simpleTime($t('date_format.hours_minutes'))}}
                    </template>
                </div>
                <div
                    v-if="message.state === 'accepted'"
                    class="text-success small">
                    <i class="far fa-check-circle mr-1"/>{{ $t('contact.absence.absence_accepted') }}
                </div>
                <div
                    v-else
                    class="text-purple small">
                    <i class="far fa-question-circle mr-1"/>{{ $t('contact.absence.absence_pending') }}
                </div>
            </div>
        </div>
    </div>

    <!-- LATE -->
    <div v-else-if="message.type === 'late'" class="timeline-item late">
        <div class="timeline-item-date">
            {{displayDate(message.created_at)}}
        </div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content">
            <div class="timeline-item-inner">
                {{getLateContent(message.late)}}
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import anchorme from "anchorme";

    import { f7Messages, f7Message } from 'framework7-vue';
    
    export default {
        components: {
            f7Messages,
            f7Message,
        },
        props: {
            message: {},
            showButton: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            anchorme() {
                return anchorme;
            }
        },
        methods: {
            getLateContent(late) {
                if (late >= 60) {
                    return this.$t('contact.late.readable_more_one_hour');
                }
                return this.$t('contact.late.readable_min', { min: late });
            },

            sameDay(message) {
                return moment.unix(message.started_at).isSame(moment.unix(message.ended_at), 'day');
            },

            displayDate(date) {
                return moment.unix(date).format(this.$t('date_format.days_months_years.short_year')) + '\n' + moment.unix(date).format(this.$t('date_format.hours_minutes'));
            }
        }
    }
</script>

<style lang="scss">
    #timeline {
        .timeline {
            margin: auto !important;
        }

        .timeline-item-date {
            font-size: 12px;
            color: #836c95;
            font-weight: 500;
            width: 70px;
            margin-top: 8px;
            text-align: right;
        }
        .timeline-item-divider {
            margin: 0 10px;
            margin-top: 9px;
            width: 15px;
            height: 15px;
            ;

            &::after,
            &::before {
                width: 5px;
                background: #f6ecff !important;
            }

            &::after {
                top: 130%;
            }

            &::before {
                bottom: 130%;
            }
        }

        .timeline-item {
            &:first-child {
                padding-top: 10px;
                .timeline-item-divider {
                    &::before {
                        content: '';
                        display: block;
                    }
                }
            }

            &:last-child {
                padding-bottom: 10px;
                .timeline-item-divider {
                    &::after {
                        content: '';
                        display: block;
                        border: 2px dashed #f0dfff;
                        background: white !important;
                    }
                }
            }

            &.late {
                .timeline-item-divider {
                    background: #f4b103;
                    border: 2px solid #ffd532;
                }
            }

            &.occasional {
                .timeline-item-divider {
                    background: #3b7aff;
                    border: 2px solid #bbd1ff;
                }
            }

            &.absence {
                .timeline-item-divider {
                    background: #f41e5d;
                    border: 2px solid #ff95b4;
                }
            }

            &.chat-message {
                margin: 0;
                .timeline-item-divider {
                    background: #55c4cc;
                    border: 2px solid #bcf5f9;
                }

                .messages {
                    background: transparent;
                }

                .message {
                    max-width: 80%;
                }

                .message-sent {
                    .message-bubble {
                        background-color: #0cc2cc;
                    }
                }

                .message-received {
                    .message-bubble {
                        background-color: #519bb7;
                        color: white;
                    }
                }
            }
        }

        .timeline-item-content {
            color: #836c95;
            width: 100%;

            a {
                color: white;
                text-decoration: underline;
            }

            .timeline-item-inner {
                padding: 4px 0;
            }
        }
    }

    @media (min-width: 768px) {
        #timeline {
            .timeline-item {
                margin-left: 0 !important;
                &:not(.timeline-item-right):nth-child(2n) {
                    flex-direction: row !important;
                }
            }
        }
    }
</style>