import moment from 'moment';
import i18n from '@/i18n';

import _minBy from 'lodash/minBy';
import _maxBy from 'lodash/maxBy';

import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Media } from '@capacitor-community/media';

export default {
    methods: {
        async $openUrl(url, outside = false) {
            if (outside) {
                window.open(url, '_system', '');
            } else {
                await Browser.open({ url: url });
            }
        },

        async $downloadPhoto(imgUrl, isVideo) {
            const self = this;
            const originalUrl = imgUrl;

            if (Capacitor.getPlatform() === 'android' || isVideo) {
                imgUrl = await this.$getLocalImage(imgUrl, isVideo);
            }

            return new Promise((resolve, reject) => {
                if (Capacitor.isNativePlatform()) {
                    const albumName = 'Meeko Family';
                    Media
                        .getAlbums()
                        .then((res) => {
                            const meekoAlbum = res.albums.find((item) => {
                                return item.name === albumName
                            });

                            if (meekoAlbum) {
                                const album = meekoAlbum.identifier ?? meekoAlbum.name;
                                if(isVideo) {
                                    Media.saveVideo({ path: imgUrl, albumIdentifier: album });
                                } else {
                                    Media.savePhoto({ path: imgUrl, albumIdentifier: album });
                                }
                                resolve("done");
                                self.$toastSuccess(self.$t('photos.save_success'));
                            } else {
                                Media.createAlbum({
                                    name: albumName
                                }).then(async () => {
                                    const { albums } = await Media.getAlbums();
                                    const meekoAlbum = albums.find((item) => {
                                        return item.name === albumName
                                    });
                                    const album = meekoAlbum.identifier ?? meekoAlbum.name;

                                    if(isVideo) {
                                        Media.saveVideo({ path: imgUrl, albumIdentifier: album });
                                    } else {
                                        Media.savePhoto({ path: imgUrl, albumIdentifier: album });
                                    }
                                    resolve("done");
                                    self.$toastSuccess(self.$t('photos.save_success'));
                                }).catch((err) => {
                                    reject("error");
                                    console.log(err);
                                    self.$toastError(self.$t('photos.save_error'));
                                    self.$openUrl(originalUrl);
                                });
                            }
                        }).catch(() => {
                            resolve("done");
                            self.$openUrl(originalUrl);
                        });
                } else {
                    resolve("done");
                    self.$openUrl(originalUrl);
                }
            });
        },

        async $getLocalImage(imgUrl, isVideo) {
            return new Promise((resolve) => {
                this.$toDataURL(imgUrl, async (base64Url) => {
                    try {
                        const result = await Filesystem.writeFile({
                            path: isVideo ? 'video.mp4' : 'image.jpeg',
                            data: base64Url,
                            directory: Directory.Cache
                        })
                        resolve(result.uri);
                    } catch (e) {
                        console.error('Unable to write file: ', e);
                        resolve(imgUrl);
                    }
                });
            });
        },

        $getDeviceInfo() {
            let shouldUpdate = false;
            let local_device_os_version = String(localStorage.getItem("device_os_version"));
            let local_device_app_version = String(localStorage.getItem("device_app_version"));

            const device_os = String(this.$f7.device.os);
            const device_os_version = String(this.$f7.device.osVersion);
            const device_app_version = String(process.env.VUE_APP_VERSION);

            if (local_device_os_version !== device_os_version) {
                local_device_os_version = localStorage.setItem("device_os_version", device_os_version);
                shouldUpdate = true;
            }

            if (local_device_app_version !== device_app_version) {
                local_device_app_version = localStorage.setItem("device_app_version", device_app_version);
                shouldUpdate = true;
            }

            return {
                shouldUpdate,
                device_os,
                device_os_version,
                device_app_version,
            }
        },

        // Convert url to base64
        $toDataURL(url, callback) {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => {
                let reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = (e) => {
                console.error('Unable convert url to data: ', e);
                callback(url);
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
        },

        $getOpening(hours) {
            let hour = '07:00';
            let min = _minBy(hours, 'started_at');
            min && min.started_at ? hour = min.started_at : null;

            return moment(hour, 'HH:mm');
        },

        $getClosing(hours) {
            let hour = '18:00';
            let max = _maxBy(hours, 'ended_at');
            max && max.started_at ? hour = max.ended_at : null;

            return moment(hour, 'HH:mm');
        },

        $getInvoiceClass(state) {
            switch (state) {
                case 'paid':
                    return 'text-success';
                case 'unpaid':
                    return 'text-danger';
                case 'pending':
                    return 'text-warning';
                case 'draft':
                    return 'text-muted';
            }
        },

        /**
         * Permet de ne pas afficher les blagues (par exemple pour les crèches de  Biel)
         */
        $shouldDisplayJoke(organizationId) {
            const notFunnyOrganizations = ['0469f0d0-8048-4b6f-86c9-ccde2d64f509', '44021a78-f65c-4033-93ba-e82afcb1e3b3', '4a5d1c13-68c2-492a-8665-6d5fab151a9c', '78291093-7322-44bc-ae7a-4d72cfcd2ecc', '887f1ec1-6939-4656-a527-02378f63adf9', 'a1afc8c0-981b-4e9d-a6e9-70513f677943', 'b0735cce-698c-49d5-97ca-457c581b4e32'];
            return !notFunnyOrganizations.includes(organizationId);
        },

        $handleError(e) {
            if (e.response && e.response.status) {
                let errors = "";
                switch (e.response.status) {
                    case 401:
                        if (localStorage.getItem("connected") === 'true') {
                            this.$toastWarning(i18n.t('request_errors.disconnected'));
                        }
                        this.$http_bus.resetApi(); // Logout
                        this.$notification_bus.resetAllLocalBadges();
                        this.$bus.$emit('login', false);
                        break;
                    case 403:
                        if (e.response.data.errors && e.response.data.errors['custom']) {
                            this.$toastWarning(e.response.data.errors['custom']);
                        }
                        
                        if (e.response.data.error && e.response.data.error.data) {
                            this.$toastWarning(e.response.data.error.data);
                        }
                        break;
                    case 404:
                        this.$toastWarning(i18n.t('request_errors.retrieve_data'));
                        break;
                    case 422:
                        try {
                            let json = JSON.parse(e.request.responseText);
                            for (let data in json.errors) {
                                errors += "<li>" + json.errors[data] + "</li>";
                            }
                        } catch (e) {
                            errors = e.request.responseText;
                        }

                        this.$toastWarning("<ul>" + errors + "</ul>");
                        break;
                    case 423:
                        this.$toastWarning(i18n.t('request_errors.too_many_attempts'));
                        break;
                    default:
                        this.$toastWarning(e.response.status + i18n.t('request_errors.status_other'));
                        break;
                }
            } else {
                this.$toastError(i18n.t('request_errors.check_connection'));
            }
        },

        $handleSuccess(response) {
            if (response) {
                this.$toastSuccess(response.statusText);
            } else {
                this.$toastSuccess("OK");
            }
        },

        $toastWarning(message, options) {
            return this.$toasted.show("<i class='fa fa-exclamation-triangle pr-1'></i>" + message, options);
        },
        $toastError(message) {
            return this.$toasted.error("<i class='fa fa-exclamation-circle pr-1'></i>" + message);
        },
        $toastSuccess(message) {
            return this.$toasted.success("<i class='fa fa-check pr-1'></i>" + message);
        }
    }
}