import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDate', function (timestamp, format) {
    !Number.isInteger(timestamp) ? timestamp = moment(timestamp).unix() : null;
    let isValid = moment.unix(timestamp).isValid();

    let date = moment.unix(timestamp);
    //let tz = localStorage.getItem("nursery_timezone") ? localStorage.getItem("nursery_timezone") : 'Europe/Paris';
    format ? null : format = 'ddd DD MMM';
    //let time = date.tz(tz).format(format);
    let time = date.format(format);

    return isValid ? time : '--/--/----';
});

Vue.filter('simpleTime', function (timestamp, format) {
    !Number.isInteger(timestamp) ? timestamp = moment(timestamp).unix() : null;
    let isValid = moment.unix(timestamp).isValid();

    let date = moment.unix(timestamp);
    //let tz = localStorage.getItem("nursery_timezone") ? localStorage.getItem("nursery_timezone") : 'Europe/Paris';
    //let time = date.tz(tz).format('HH') + 'h' + date.tz(tz).format('mm');
    format ? null : format = 'HH:mm';
    let time = date.format(format);

    return isValid ? time : '-:-';
});

Vue.filter('capitalize', (str) => {
    let lower = str ? str.toLowerCase() : '';
    return lower.replace(/(^|[\s-])\S/g, (match) => {
        return match.toUpperCase();
    });
});

Vue.filter('currency', (str, nursery) => {
    let currency = nursery && nursery.currency ? nursery.currency : '€';
    return str + '' + currency;
});

Vue.filter('humanPayment', (str, self) => {
    switch (str) {
        case 'cesu':
            return self.$t('payments.payments_methods.cesu');
        case 'cash':
            return self.$t('payments.payments_methods.cash');
        case 'transfer':
            return self.$t('payments.payments_methods.transfer');
        case 'check':
            return self.$t('payments.payments_methods.check');
        case 'sepa':
            return self.$t('payments.payments_methods.sepa');
        case 'debit':
            return self.$t('payments.payments_methods.debit');
        case 'other':
            return self.$t('payments.payments_methods.other');
        case 'credit_note':
            return self.$t('payments.payments_methods.credit_note');
        default:
            return self.$t('payments.payments_methods.other')
    }
});