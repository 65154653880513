<template>
    <f7-page id="news" name="news">
        <f7-navbar>
            <f7-nav-left>&nbsp;</f7-nav-left>
            <f7-nav-title class="grandhotel">{{ $t('news.title') }}</f7-nav-title>
            <f7-nav-right>&nbsp;</f7-nav-right>
        </f7-navbar>

        <div v-if="has_access" id="news-container" class="news-container infinite-scroll infinite-scroll-top">
            <div v-if="!allNews.length && !loading" class="mt-5">
                <h2 class="grandhotel text-pink text-center p-3">{{ $t('news.empty_data') }}</h2>
            </div>
            <div v-for="(news, i) in allNews" :key="i" class="news" :class="{'card-pink': i % 2}">
                <f7-card>
                    <f7-card-header>
                        <div>
                            <div class="date">
                                <div class="day">{{getDay(news.posted_at)}}</div>
                                <div class="month">{{getMonth(news.posted_at)}}</div>
                            </div>
                            <div class="grandhotel text-capitalize-first title">{{news.title}}</div>
                        </div>
                    </f7-card-header>
                    <f7-card-content :inner="false">
                        <div class="news-content" v-html="anchorme(news.content)"></div>
                    </f7-card-content>
                </f7-card>
                <div v-if="news.sponsored" class="text-meeko">MEEKO INFO</div>
            </div>
        </div>
        <check-access v-else v-model="has_access"></check-access>

        <content-loader v-if="loading" template="news"></content-loader>
        <!-- <div v-if="loading" class="infinite-scroll-preloader">
            <div class="preloader"></div>
        </div> -->
    </f7-page>
</template>

<script>
    import moment from 'moment';
    import $ from 'jquery';
    import anchorme from "anchorme";
    import { Base64 } from 'js-base64';

    import _concat from 'lodash/concat';

    import { f7Page, f7Navbar, f7NavLeft, f7NavTitle, f7NavRight, f7Card, f7CardHeader, f7CardContent } from 'framework7-vue';

    export default {
        components: {
            f7Page,
            f7Navbar,
            f7NavLeft,
            f7NavTitle,
            f7NavRight,
            f7Card,
            f7CardHeader,
            f7CardContent,
        },
        data() {
            return {
                allNews: [],
                last_loaded_page: 1,
                last_page: null,
                loading: false,
                has_access: this.$http_bus.has_access
            }
        },
        computed: {
            anchorme() {
                return anchorme;
            }
        },
        mounted() {
            setTimeout(() => {
                this.$bus.$on('resume', () => {
                    localStorage.getItem("connected") === 'true' ? this.getNews() : null;
                });
            }, 400);

            this.$notification_bus.resetLocalBadge('new-post-v1');

            this.getNews();

            let self = this;
            $('#news .page-content').on('scroll', function () {
                if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight - 20) {
                    self.getNews(true);
                }
            });
        },
        destroyed() {
            this.$bus.$off('resume');
        },
        methods: {
            getNews(newPage) {
                if (this.loading) {
                    return;
                }

                if (newPage && this.last_loaded_page === this.last_page) {
                    return;
                }

                this.loading = true;
                this.$http.get('/news', {
                    params: {
                        page: newPage ? this.last_loaded_page + 1 : 1
                    }
                }).then((response) => {
                    let nursery_data = response.data.data;
                    nursery_data.forEach((item) => {
                        if(this.isBase64(item.content)) {
                            item.content = Base64.decode(item.content);
                        }
                    });

                    if (newPage) {
                        this.allNews = _concat(this.allNews, nursery_data);
                    } else {
                        this.allNews = nursery_data;
                    }
                    this.last_loaded_page = response.data.current_page;
                    this.last_page = response.data.last_page;
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            },

            getMonth(date) {
                return moment.unix(date).format('MMM').toUpperCase();
            },

            getDay(date) {
                return moment.unix(date).format('DD');
            },

            isBase64(str) {
                try {
                    return Base64.encode(Base64.decode(str)) == str;
                } catch (err) {
                    return false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .infinite-scroll-preloader {
        margin-bottom: 20px;
        text-align: center;
    }

    .infinite-scroll-preloader .preloader {
        width: 34px;
        height: 34px;
    }

    .news-container {
        .news {
            position: relative;
            .card {
                border: none;
                background-color: #9B6BEC;
                color: white;
                margin-bottom: 20px;
                border-radius: 10px;
                overflow: hidden;

                .card-header {
                    color: white;
                    min-height: 35px;
                    background-color: #8147E8;
                    padding: 0;
                    border: none;

                    &::after {
                        height: 0;
                    }

                    .date {
                        float: left;
                        width: 50px;
                        text-align: center;
                        line-height: 15px;
                        padding: 5px;
                        border-right: 1px solid rgba(255, 255, 255, 0.4);
                        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
                        border-radius: 5px;

                        .month {
                            font-size: 11px;
                            font-weight: 400;
                        }
                        .day {
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }

                    .title {
                        float: left;
                        margin-left: 10px;
                        font-size: 22px;
                        padding-top: 3px;
                        max-width: 70vw;
                    }
                }

                .card-content {
                    padding: 8px 12px;
                    overflow: hidden;

                    .news-content {
                        white-space: pre-line;
                    }
                }
            }

            &.card-pink {
                margin-bottom: 25px;
                .card {
                    background-color: #FF50B4;
                    .card-header {
                        background-color: #FF12A3;
                    }
                }
            }

            .text-meeko {
                position: absolute;
                right: 15px;
                bottom: -17px;
                font-size: 10px;
                font-weight: 600;
                color: gray;
            }
        }
    }
</style>

<style lang="scss">
    .news-container {
        .news {
            .card-content {
                p {
                    margin: 0;
                }
                img, video {
                    max-width: 100%;
                    border-radius: 5px;
                    margin-top: 8px;
                    margin-bottom: 8px;
                }
                .news-content {
                    a {
                        color: white;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
</style>