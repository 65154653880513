<template>
    <f7-page class="home" name="home">
        <f7-navbar>
            <f7-nav-left :class="{'mr-5': !showSelectKid}">
                <span v-if="!showSelectKid">&nbsp;</span>
                <f7-link v-else icon="fa fa-child" color="white" :icon-size="22" popover-open=".popover-kids"></f7-link>
            </f7-nav-left>
            <f7-nav-title class="grandhotel">{{ $t('home.title') }}</f7-nav-title>
            <f7-nav-right class="ml-0">
                <f7-link v-if="showList" @click="switchDisplay" icon="fa fa-th-list" color="white" :icon-size="22"></f7-link>
                <f7-link v-else @click="switchDisplay" icon="fa fa-th-large" color="white" :icon-size="22"></f7-link>
            </f7-nav-right>
        </f7-navbar>

        <f7-row class="bg-purple-light p-2 m-0" style="position: relative; z-index: 1061;">
            <f7-col @click.native="previousDay" width="15" class="pl-2">
                <f7-link class="text-pink">
                    <i class="far fa-angle-left" style="font-size: 34px;"/>
                </f7-link>
            </f7-col>
            <f7-col width="70" class="text-center pt-1">
                <v-datepicker v-model="currentDate"></v-datepicker>
            </f7-col>
            <f7-col @click.native="nextDay" width="15" class="text-right pr-2">
                <f7-link class="text-pink">
                    <i class="far fa-angle-right" style="font-size: 34px;"/>
                </f7-link>
            </f7-col>
        </f7-row>

        <content-loader v-if="loading"></content-loader>
        <div v-else-if="has_access" class="daily">
            <home-grid v-if="showList" :kid="currentKid" :loading="loading" class="p-1"></home-grid>
            <home-list v-else :kids="kids" :loading="loading"></home-list>
        </div>
        <check-access v-else v-model="has_access"></check-access>

        <f7-popover ref="popoverKids" v-if="showSelectKid" class="popover-kids">
            <div class="p-3">
                <button v-for="(kid, i) in kids" :key="i" @click="selectKid(kid.id)" class="btn btn-block btn-purple mt-2">
                    {{kid.first_name}} {{kid.last_name}}
                </button>
            </div>
        </f7-popover>
    </f7-page>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';

    import _concat from 'lodash/concat';

    import HomeGrid from './HomeGrid';
    import HomeList from './HomeList';

    import { f7Page, f7Navbar, f7NavLeft, f7NavTitle, f7NavRight, f7Row, f7Col, f7Popover, f7Link } from 'framework7-vue';

    export default {
        components: {
            HomeGrid,
            HomeList,
            f7Page,
            f7Navbar,
            f7NavLeft,
            f7NavTitle,
            f7NavRight,
            f7Row,
            f7Col,
            f7Popover,
            f7Link,
        },
        data() {
            return {
                kids: [],
                currentKid: {},
                currentDate: moment().format(),
                fromDate: null,
                toDate: null,
                selectedKid: null,
                loading: false,
                showList: window.localStorage.getItem('showList') === 'true',
                has_access: this.$http_bus.has_access
            }
        },
        computed: {
            showSelectKid() {
                return this.showList && this.kids.length > 1;
            },
        },
        watch: {
            currentDate() {
                this.getKids();
            }
        },
        mounted() {
            setTimeout(() => {
                this.$bus.$on('resume', () => {
                    localStorage.getItem("connected") === 'true' ? this.getKids() : null;
                });
            }, 400);

            this.$notification_bus.resetLocalBadge('new-photo-v1');
            this.$notification_bus.resetLocalBadge('new-video-v1');

            this.getKids();
        },
        destroyed() {
            this.$bus.$off('resume');
        },
        methods: {
            getKids() {
                let fromDate = moment(this.currentDate).clone().startOf('day').unix();
                let toDate = moment(this.currentDate).clone().endOf('day').unix();

                this.loading = true;

                this.$http.get('/kids', {
                    params: {
                        from: fromDate,
                        to: toDate
                    }
                }).then((response) => {
                    this.kids = response.data.filter((item) => item.nursery_id.toString() === this.$http_bus.nursery_id);
                    this.kids.length && this.selectedKid === null ? this.selectedKid = this.kids[0].id : null;
                    this.currentKid = this.kids.find((kid) => kid.id === this.selectedKid);
                    
                    this.kids.forEach((kid) => {
                        kid.drugs.forEach((drug) => {
                            Vue.set(drug, 'category', 'drug');
                        });
                        kid.temperatures.forEach((temperature) => {
                            Vue.set(temperature, 'category', 'temperature');
                        });
                        kid.weights.forEach((weight) => {
                            Vue.set(weight, 'category', 'weight');
                        });
                        let healths = _concat([], kid.drugs, kid.weights, kid.temperatures);
                        Vue.set(kid, 'healths', healths);
                    });
                    
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            },

            switchDisplay() {
                this.showList = !this.showList;
                window.localStorage.setItem('showList', this.showList);
            },

            previousDay() {
                this.currentDate = moment(this.currentDate).subtract(1, 'day').format();
            },

            nextDay() {
                this.currentDate = moment(this.currentDate).add(1, 'day').format();
            },

            selectKid(id) {
                this.$refs.popoverKids.close();
                this.selectedKid = id;
                this.currentKid = this.kids.find((kid) => kid.id === this.selectedKid);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .home {
        h5 {
            font-size: 22px;
        }
        .daily {
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
            height: 90%;
        }
    }
</style>

<style lang="scss">
    .home {
        .page-content {
            overflow: hidden;
        }
    }
</style>