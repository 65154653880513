<template>
    <div class="d-flex h-50 justify-content-center">
        <div class="align-self-center">
            <i class="fa" :class="[{'fa-4x': lg}, bubble ? 'fa-pulse fa-spinner' : 'fa-circle-notch fa-spin']"></i>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            lg: {
                type: Boolean,
                default: false
            },
            bubble: {
                type: Boolean,
                default: false
            }
        }
    }
</script>