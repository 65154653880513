import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

try {
    require('bootstrap');
} catch (e) {
    //
}

// Axios
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';

window.API_URL = process.env.VUE_APP_API_URL;
window.PUSHER_KEY = process.env.VUE_APP_PUSHER_KEY;

window.Pusher = require('pusher-js');

// Moment
let lang = window.localStorage.getItem('lang');
lang ? moment.locale(lang) : null;

// Bugsnag
if (process.env.VUE_APP_MEEKO_API === 'local') {
  // LocalBugsnag
} else {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginVue()],
    enabledReleaseStages: ['production', 'staging'],
    releaseStage: process.env.NODE_ENV,
    appVersion: process.env.VUE_APP_BUGSNAG_VERSION,
    onError: function (event) {
      event.addMetadata('user', {
        user_id: localStorage.getItem("user_id"),
        nursery: localStorage.getItem("nursery_id"),
        internal_version: process.env.VUE_APP_VERSION,
      })
    },
  });
  Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);
}