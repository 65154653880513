import Vue from 'vue';

let NotificationBus = new Vue();

NotificationBus.badges = {
    home: window.localStorage.getItem('notificationCount:home') ? window.localStorage.getItem('notificationCount:home') : 0,
    news: window.localStorage.getItem('notificationCount:news') ? window.localStorage.getItem('notificationCount:news') : 0,
    contact: window.localStorage.getItem('notificationCount:contact') ? window.localStorage.getItem('notificationCount:contact') : 0,
    documents: window.localStorage.getItem('notificationCount:documents') ? window.localStorage.getItem('notificationCount:documents') : 0,
    invoices: window.localStorage.getItem('notificationCount:invoices') ? window.localStorage.getItem('notificationCount:invoices') : 0
};

NotificationBus.setLocalBadge = (notification) => {
    if (notification && notification.type) {
        const type = notification.type;
        switch (type) {
            case 'new-photo-v1':
                NotificationBus.badges.home = parseInt(NotificationBus.badges.home) + 1;
                window.localStorage.setItem('notificationCount:home', NotificationBus.badges.home);
                break;
            case 'new-video-v1':
                NotificationBus.badges.home = parseInt(NotificationBus.badges.home) + 1;
                window.localStorage.setItem('notificationCount:home', NotificationBus.badges.home);
                break;
            case 'new-post-v1':
                NotificationBus.badges.news = parseInt(NotificationBus.badges.news) + 1;
                window.localStorage.setItem('notificationCount:news', NotificationBus.badges.news);
                break;
            case 'new-message-v1':
                NotificationBus.badges.contact = parseInt(NotificationBus.badges.contact) + 1;
                window.localStorage.setItem('notificationCount:contact', NotificationBus.badges.contact);
                break;
            case 'new-document-v1':
                NotificationBus.badges.documents = parseInt(NotificationBus.badges.documents) + 1;
                window.localStorage.setItem('notificationCount:documents', NotificationBus.badges.documents);
                break;
            case 'updated-document-v1':
                NotificationBus.badges.documents = parseInt(NotificationBus.badges.documents) + 1;
                window.localStorage.setItem('notificationCount:documents', NotificationBus.badges.documents);
                break;
            case 'new-invoice-v1':
                NotificationBus.badges.invoices = parseInt(NotificationBus.badges.invoices) + 1;
                window.localStorage.setItem('notificationCount:invoices', NotificationBus.badges.invoices);
                break;
            default:
                return;
        }

        NotificationBus.$emit('refreshBadgesOther');
        NotificationBus.$emit('refreshBadges');
    }
}

NotificationBus.resetLocalBadge = (type) => {
    switch (type) {
        case 'new-photo-v1':
            NotificationBus.badges.home = 0;
            window.localStorage.removeItem('notificationCount:home');
            break;
        case 'new-video-v1':
            NotificationBus.badges.home = 0;
            window.localStorage.removeItem('notificationCount:home');
            break;
        case 'new-post-v1':
            NotificationBus.badges.news = 0;
            window.localStorage.removeItem('notificationCount:news');
            break;
        case 'new-message-v1':
            NotificationBus.badges.contact = 0;
            window.localStorage.removeItem('notificationCount:contact');
            break;
        case 'new-document-v1':
            NotificationBus.badges.documents = 0;
            window.localStorage.removeItem('notificationCount:documents');
            break;
        case 'new-invoice-v1':
            NotificationBus.badges.invoices = 0;
            window.localStorage.removeItem('notificationCount:invoices');
            break;
        default:
            return;
    }

    NotificationBus.$emit('refreshBadgesOther');
    NotificationBus.$emit('refreshBadges');
}

NotificationBus.resetAllLocalBadges = () => {
    NotificationBus.badges.home = 0;
    window.localStorage.removeItem('notificationCount:home');

    NotificationBus.badges.news = 0;
    window.localStorage.removeItem('notificationCount:news');

    NotificationBus.badges.contact = 0;
    window.localStorage.removeItem('notificationCount:contact');

    NotificationBus.badges.documents = 0;
    window.localStorage.removeItem('notificationCount:documents');

    NotificationBus.badges.invoices = 0;
    window.localStorage.removeItem('notificationCount:invoices');

    NotificationBus.$emit('refreshBadgesOther');
    NotificationBus.$emit('refreshBadges');
}

Object.defineProperties(Vue.prototype, {
    $notification_bus: {
        get: () => {
            return NotificationBus;
        }
    },
});