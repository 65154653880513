import Vue from 'vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
    position: 'top-right',
    duration: 4500,
    theme: 'bubble'
});

import Loader from '@/components/Loader';
import ContentLoader from '@/components/ContentLoader';
import Avatar from '@/components/Avatar';
import DatePicker from '@/components/DatePicker';
import CustomModal from '@/components/CustomModal';
import CheckAccess from '@/components/CheckAccess';

Vue.component('loader', Loader);
Vue.component('content-loader', ContentLoader);
Vue.component('avatar', Avatar);
Vue.component('v-datepicker', DatePicker);
Vue.component('custom-modal', CustomModal);
Vue.component('check-access', CheckAccess);