<template>
    <div>
        <!-- Presences / Meals -->
        <f7-row no-gutter>
            <f7-col width="50">
                <f7-card class="presences mr-0" v-if="kid">
                    <f7-card-header class="text-uppercase">{{ $tc('daily_reports.attendances.title', 2) }}</f7-card-header>
                    <f7-card-content :inner="false" class="p-0 pt-1">
                        <div v-for="(presence, i) in plannings" :key="i">
                            <div v-if="presence.type === 'arrival'" class="daily-row row">
                                <div class="col">{{ $t('daily_reports.attendances.drop_off') }}</div>
                                <div class="col text-right time">{{presence.droped_at | simpleTime($t('date_format.hours_minutes'))}}</div>
                            </div>
                            <div v-else class="daily-row row">
                                <div class="col">{{ $t('daily_reports.attendances.pick_up') }}</div>
                                <div class="col text-right time">{{presence.picked_up_at | simpleTime($t('date_format.hours_minutes'))}}</div>
                            </div>
                        </div>
                    </f7-card-content>
                    <div class="bg-icon">
                        <i class="fa fa-clock"></i>
                    </div>
                </f7-card>
            </f7-col>
            <f7-col width="50">
                <f7-card class="meals ml-0" v-if="kid">
                    <f7-card-header class="text-uppercase">{{ $tc('daily_reports.meals.title', 2) }}</f7-card-header>
                    <f7-card-content :inner="false" class="p-0 pt-1">
                        <template v-for="(meal, i) in kid.meals" >
                            <div v-if="meal.done_at" :key="'meal' + i" class="daily-row row align-items-center">
                                <div class="col-8">
                                    <span v-if="meal.milk !== null">{{ $t('daily_reports.meals.baby_bottle.quantity_of_milk', {milk: meal.milk}) }}</span>
                                    <span v-else-if="meal.breastfeeding">{{ $t('daily_reports.meals.breastfeeding.title') }}</span>
                                    <span v-else-if="meal.rating === null">{{ $t('daily_reports.meals.rating_nothing') }}</span>
                                    <span v-else-if="meal.rating === 0">{{ $t('daily_reports.meals.rating_little') }}</span>
                                    <span v-else-if="meal.rating === 1">{{ $t('daily_reports.meals.rating_average') }}</span>
                                    <span v-else-if="meal.rating === 2">{{ $t('daily_reports.meals.rating_good') }}</span>
                                </div>
                                <div class="col-4 text-right time">{{meal.done_at | simpleTime($t('date_format.hours_minutes'))}}</div>
                            </div>
                        </template>
                    </f7-card-content>
                    <div class="bg-icon">
                        <i class="fa fa-utensils-alt"></i>
                    </div>
                </f7-card>
            </f7-col>
        </f7-row>

        <!-- Activites / Naps -->
        <f7-row no-gutter>
            <f7-col width="50">
                <f7-card class="activities mr-0" v-if="kid">
                    <f7-card-header class="text-uppercase">{{ $tc('daily_reports.activities.title', 2) }}</f7-card-header>
                    <f7-card-content :inner="false" class="p-0 pt-1">
                        <div v-for="(activity, i) in kid.activities" :key="i" class="daily-row row align-items-center">
                            <div class="col-8 ellipsis">{{activity.name}}</div>
                            <div class="col-4 text-right time">{{activity.done_at | simpleTime($t('date_format.hours_minutes'))}}</div>
                        </div>
                    </f7-card-content>
                    <div class="bg-icon">
                        <i class="fa fa-palette"></i>
                    </div>
                </f7-card>
            </f7-col>
            <f7-col width="50">
                <f7-card class="naps ml-0" v-if="kid">
                    <f7-card-header class="text-uppercase">{{ $tc('daily_reports.naps.title', 2) }}</f7-card-header>
                    <f7-card-content :inner="false" class="p-0 pt-1">
                        <div v-for="(nap, i) in kid.naps" :key="i" class="daily-row row align-items-center">
                            <div class="col-3 text-nowrap">{{getDuration(nap.started_at, nap.ended_at)}}</div>
                            <div class="col-9 text-right time">{{nap.started_at | simpleTime($t('date_format.hours_minutes'))}}
                                <template v-if="nap.ended_at">- {{nap.ended_at | simpleTime($t('date_format.hours_minutes'))}}</template>
                            </div>
                        </div>
                    </f7-card-content>
                    <div class="bg-icon">
                        <i class="fa fa-moon"></i>
                    </div>
                </f7-card>
            </f7-col>
        </f7-row>

        <!-- Hygiene / Health -->
        <f7-row no-gutter>
            <f7-col width="50">
                <f7-card class="hygiene mr-0" v-if="kid">
                    <f7-card-header class="text-uppercase">{{ $t('daily_reports.hygiene.title') }}</f7-card-header>
                    <f7-card-content :inner="false" class="p-0 pt-1">
                        <div v-for="(diaper, i) in kid.diapers" :key="i" class="daily-row row">
                            <div class="col">
                                <span v-if="diaper.type === null || diaper.isHygiene">{{ $t('daily_reports.hygiene.title') }}</span>
                                <span v-else-if="diaper.type === 'diaper'">{{ $t('daily_reports.hygiene.diaper') }}</span>
                                <span v-else-if="diaper.type === 'potty'">{{ $t('daily_reports.hygiene.potty') }}</span>
                                <span v-else-if="diaper.type === 'toilet'">{{ $t('daily_reports.hygiene.toilet') }}</span>
                            </div>
                            <div class="col text-right time">{{diaper.done_at | simpleTime($t('date_format.hours_minutes'))}}</div>
                        </div>
                    </f7-card-content>
                    <div class="bg-icon">
                        <i class="fa fa-bath"></i>
                    </div>
                </f7-card>
            </f7-col>
            <f7-col width="50">
                <f7-card class="health ml-0" v-if="kid">
                    <f7-card-header class="text-uppercase">{{ $t('daily_reports.health.title') }}</f7-card-header>
                    <f7-card-content :inner="false" class="p-0 pt-1">
                        <template v-for="(health, i) in kid.healths">
                            <div v-if="health.done_at" :key="'health' + i" class="daily-row row">
                                <div class="col">
                                    <span class="text-capitalize" v-if="health.category === 'drug'">{{health.name}}</span>
                                    <span v-else-if="health.category === 'temperature'">{{health.degree}} °C</span>
                                    <span v-else-if="health.category === 'weight'">{{health.kilogram}} kg</span>
                                </div>
                                <div class="col text-right time">{{health.done_at | simpleTime($t('date_format.hours_minutes'))}}</div>
                            </div>
                        </template>
                    </f7-card-content>
                    <div class="bg-icon">
                        <i class="fa fa-heart"></i>
                    </div>
                </f7-card>
            </f7-col>
        </f7-row>
    </div>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';

    import _concat from 'lodash/concat';

    import { f7Row, f7Col, f7Card, f7CardHeader, f7CardContent } from 'framework7-vue';

    export default {
        components: {
            f7Row,
            f7Col,
            f7Card,
            f7CardHeader,
            f7CardContent,
        },
        props: {
            kid: {},
            loading: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            plannings() {
                if (!this.kid.presences) {
                    return [];
                }

                let arrivals = [];
                let departures = [];

                this.kid.presences.forEach((presence) => {
                    Vue.set(presence, 'type', 'arrival');
                    arrivals.push(presence);

                    if (presence.picked_up_at) {
                        let departure = Object.assign({}, presence);
                        Vue.set(departure, 'type', 'departure');
                        departures.push(departure);
                    }
                });

                return _concat(arrivals, departures);
            }
        },
        methods: {
            getDuration(start, end) {
                let time1 = Number.isInteger(start) ? moment.unix(start) : moment(start);
                let time2 = Number.isInteger(end) ? moment.unix(end) : moment(end);
                let tempTime = moment.duration(time2.diff(time1));

                let totalMinutes = (tempTime.hours() * 60) + tempTime.minutes();
                if (totalMinutes >= 60) {
                    let humanTime = tempTime.hours() + 'h';
                    if (tempTime.minutes() > 0) {
                        humanTime += tempTime.minutes() > 10 ? tempTime.minutes() : '0' + tempTime.minutes();
                    }

                    return humanTime;
                } else if (totalMinutes <= 0 || isNaN(tempTime)) {
                    return 'Zzz'
                } else {
                    return totalMinutes + 'min';
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
    @import "../../sass/utilities";

    .daily {
        .row {
            margin: 0;
        }

        .card {
            height: 140px;
            border: none;
            color: white;
            margin: 5px;
            border-radius: 10px;
            position: relative;
            overflow: hidden;

            .card-header {
                min-height: 35px;
                font-size: 15px;
                font-weight: 700;
                padding: 0 10px;
                border: none;
                background-color: rgba(255, 255, 255, 0.3);
                background-blend-mode: overlay;
                -webkit-justify-content: baseline;
                justify-content: baseline;

                &::after {
                    height: 0;
                }
            }

            .card-content {
                overflow-y: scroll;

                .daily-row {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
                    width: 90%;
                    margin: auto;
                    margin-top: 4px;
                    padding-bottom: 2px;
                    &:last-child {
                        border: none;
                    }
                    >div {
                        padding: 0;
                        font-size: 13px;
                        font-weight: 600;
                        &.text-right {
                            font-weight: 700;
                        }
                    }
                    .time {
                        font-size: 11px;
                    }
                }
            }

            .bg-icon {
                position: absolute;
                bottom: -8px;
                right: -4px;

                .fa {
                    font-size: 80px;
                    opacity: 0.25;
                }
            }

            &.presences {
                @include gradient45(#3C75FA, #3CB2FA); //@include gradient45(#FA326B, #FA32A6);
            }

            &.meals {
                @include gradient45(#3DD26D, #59D23D);
            }

            &.naps {
                @include gradient45(#36489C, #4C5FB7);
            }

            &.health {
                @include gradient45(#F61C1C, #FF3B6F);
            }

            &.hygiene {
                @include gradient45(#BE1CF6, #DA3BFF);
            }

            &.activities {
                @include gradient45(#fd7f35, #fa793c);
            }
        }
    }
</style>
