import axios from 'axios';

const multipartChunkSize = 5 * 1024 * 1024;

export function getFileChunk(file, part) {
    const start = (part - 1) * multipartChunkSize;
    const end = Math.min((start + multipartChunkSize), file.size);
    return file.slice(start, end);
}

export function presignRequest(file, key, partNumber, uploadId, hash) {
    return new Promise(function (resolve, reject) {
        http().post('/transfers/part-put-url', {
            part_number: partNumber,
            upload_id: uploadId,
            key: key,
            hash: hash,
            name: file.name,
            type: file.type,
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function putObject(url, blob, file) {
    return new Promise(function (resolve, reject) {
        http(false).put(url, blob, {
            headers: {
                'Content-Type': file.type,
            },
            transformRequest: [(data, headers) => {
                delete headers.common.Authorization;
                return data;
            }],
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function processQueue(queue, file, uploadId, key, hash, resolve, reject) {
    // Get the first job in the queue then remove it from queue
    let job = queue.shift();

    presignRequest(file, key, job.currentPart, uploadId, hash).then((response) => {
        uploadId = response.upload_id;
        key = response.key;
        hash = response.hash;

        putObject(response.url, job.blob, file).then(() => {
            if (queue.length) {
                // There is more job in the queue
                processQueue(queue, file, uploadId, key, hash, resolve, reject);
            } else {
                // No more job in the queue
                http().post('/transfers/complete-mpp', {
                    upload_id: uploadId,
                    key: key,
                    hash: hash,
                }).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            }
        }).catch((error) => {
            job.try++;
            queue.push(job);

            // Stop after 3 tries
            if (job.retry <= 3) {
                // Wait 10 seconds and retry
                setTimeout(() => {
                    processQueue(queue, file, uploadId, key, hash, resolve, reject);
                }, 10000);
            } else {
                // Stop uploading, it won't work :(
                reject(error);
            }
        });
    }).catch((error) => {
        job.try++;
        queue.push(job);

        // Stop after 3 tries
        if (job.retry <= 3) {
            // Wait 10 seconds and retry
            setTimeout(() => {
                processQueue(queue, file, uploadId, key, hash, resolve, reject);
            }, 10000);
        } else {
            // Stop uploading, it won't work :(
            reject(error);
        }
    });
}

export function upload(file) {
    return new Promise(function (resolve, reject) {
        const totalParts = file ? Math.ceil(file.size / multipartChunkSize) : 0;

        let queue = [];
        let currentPart = 1;

        do {
            queue.push({
                blob: getFileChunk(file, currentPart),
                currentPart: currentPart,
                try: 1,
            });

            currentPart++;
        } while (currentPart <= totalParts);

        processQueue(queue, file, undefined, undefined, undefined, (response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
}

function http(auth = true) {
    const headers = {
        Authorization: 'Bearer ' + localStorage.getItem('api_token'),
    }
    return axios.create({
        baseURL: process.env.VUE_APP_API_URL + '/v1',
        headers: auth ? headers : undefined,
    });
}