<template>
    <div class="modal fade w-100" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header" v-if="showHeader">
                    <h5 class="modal-title text-center grandhotel">{{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" :style="bodyStyle">
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer" v-if="showFooter">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String
            },
            showHeader: {
                type: Boolean,
                default: true
            },
            showFooter: {
                type: Boolean,
                default: false
            },
            bodyStyle: {
                type: String
            }
        }
    }
</script>