<template>
    <f7-page id="photosList">
        <f7-navbar>
            <f7-nav-left>
                <f7-link
                    back
                    class="back"
                    icon="fa fa-chevron-left"
                    color="white"
                    :icon-size="16"
                >&nbsp;{{ $t('navigation.back') }}</f7-link>
            </f7-nav-left>
            <f7-nav-title class="grandhotel">{{ $t('photos.title') }}</f7-nav-title>
            <!-- DOWNLOAD ALL BUTTON -->
            <!-- <f7-nav-right class="ml-0">
                <f7-link
                    icon="fa fa-ellipsis-h"
                    color="white"
                    :icon-size="22"
                    popover-open=".popover-photos-more"
                />
            </f7-nav-right> -->
        </f7-navbar>

        <content-loader
            v-if="loading"
            template="news"
        />
        <div
            class="h-100"
            v-else-if="photos.length"
        >
            <f7-swiper
                ref="galleryTop"
                :params="galleryTopParams"
                class="gallery-top"
            >
                <f7-swiper-slide
                    v-for="(img, i) in photos"
                    :key="'img'+i"
                    class="image-slide position-relative d-flex align-items-center"
                >
                    <VideoPlayer
                        v-if="img.video"
                        :url="img.photo_url"
                        :extension-type="img.type"
                        class="video-slide"
                    />
                    <img
                        v-else
                        :src="img.photo_url"
                        :alt="$t('daily_reports.photos.alt')"
                        width="100%"
                        height="auto"
                    />
                    <div class="image-actions align-items-center px-2">
                        <div
                            class="ml-2"
                        >{{img.taken_at | formatDate($t('date_format.days_months_hours.simple'))}}</div>
                        <div class="d-flex ml-auto">
                            <button
                                class="btn btn-link p-0 text-white mr-4"
                                @click="$openUrl(img.photo_url, true)"
                            >
                                <i class="far fa-external-link-alt" />
                            </button>
                            <button
                                v-if="!img.downloading"
                                class="btn btn-link p-0 text-white mr-2"
                                @click="downloadPhoto(img)"
                            >
                                <i class="fas fa-cloud-download" />
                            </button>
                            <button
                                v-else
                                class="btn btn-link p-0 text-white mr-2"
                            >
                                <i class="fa fa-circle-notch fa-spin download" />
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="img.description"
                        class="position-absolute text-white py-2 px-3 w-100"
                        style="bottom: 0; background: rgba(0, 0, 0, 0.5);"
                    >{{ img.description }}</div>
                </f7-swiper-slide>
            </f7-swiper>

            <f7-swiper
                ref="galleryThumbs"
                :params="galleryThumbsParams"
                class="gallery-thumbs"
            >
                <f7-swiper-slide
                    v-for="(img, i) in photos"
                    :ref="'thumbs' + i"
                    :key="'img' + i"
                    class="image-slide position-relative"
                >
                    <img
                        :src="img.thumbnail_url"
                        :alt="$t('daily_reports.photos.alt')"
                        width="100%"
                        height="100%"
                        style="object-fit: cover;"
                    />
                    <div
                        v-if="img.video"
                        class="d-flex position-absolute rounded-circle bg-dark align-items-center justify-content-center"
                        style="width: 40px; height: 40px; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: .8;"
                    >
                        <i class="fa fa-play text-white" style="font-size: 12px; padding-left: 3px;" />
                    </div>
                    <div
                        v-if="i === photos.length - 1"
                        v-observe-visibility="visibilityChanged"
                    />
                </f7-swiper-slide>
                <f7-swiper-slide
                    v-if="current_page < last_page"
                    class="load-more w-25 h-100 bg-dark"
                >
                    <div class="d-flex h-100 align-items-center justify-content-center">
                        <i class="fa fa-2x fa-spinner fa-spin text-white" />
                    </div>
                </f7-swiper-slide>
            </f7-swiper>
        </div>
        <div
            v-else
            class="mt-5"
        >
            <h2 class="grandhotel text-pink text-center p-3">{{ $t('photos.empty_data') }}</h2>
        </div>

        <f7-popover
            ref="popoverMore"
            class="popover-photos-more"
        >
            <div class="p-3">
                <button
                    v-if="downloadAllLoading"
                    class="btn btn-block btn-purple mt-2"
                    disabled
                >
                    <i class="fa fa-spin fa-spinner mr-2" />
                    {{ $t('photos.download_all') }}
                </button>
                <button
                    v-else
                    class="btn btn-block btn-purple mt-2"
                    @click="downloadAll"
                >{{ $t('photos.download_all') }}</button>
            </div>
        </f7-popover>
    </f7-page>
</template>

<script>
    import Vue from 'vue';

    import _concat from 'lodash/concat';

    import VideoPlayer from '@/components/Medias/VideoPlayer.vue';
    import {
        f7Page,
        f7Navbar,
        f7NavLeft,
        f7NavTitle,
        f7Link,
        f7Swiper,
        f7SwiperSlide,
        f7Popover,
    } from 'framework7-vue';

    export default {
        components: {
            f7Page,
            f7Navbar,
            f7NavLeft,
            f7NavTitle,
            f7Link,
            f7Swiper,
            f7SwiperSlide,
            f7Popover,
            VideoPlayer,
        },
        data() {
            return {
                photos: [],
                loading: false,
                downloadAllLoading: false,
                newImgLoading: false,
                current_page: 1,
                last_page: 1,

                galleryTopParams: {
                    thumbs: {
                        swiper: {
                            el: '.gallery-thumbs',
                            slidesPerView: 5,
                        },
                    },
                    on: {
                        reachEnd: () => {
                            if (this.current_page < this.last_page) {
                                this.getNextPhotos();
                            }
                        },
                        slideChange: () => {
                            const videos = document.getElementsByClassName('video-slide');
                            for (let video of videos) {
                                !video.paused ? video.pause() : null;
                            }
                        }
                    },
                },

                galleryThumbsParams: {
                    spaceBetween: 0,
                    slidesPerView: 5,
                    freeMode: true,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                },
            };
        },
        mounted() {
            this.getPhotos();
        },
        methods: {
            getPhotos() {
                this.loading = true;

                this.$http
                    .get('/photos', {
                        params: {
                            per_page: 6,
                        },
                    })
                    .then((response) => {
                        this.loading = false;
                        this.photos = response.data.data;
                        this.current_page = response.data.current_page;
                        this.last_page = response.data.last_page;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },

            getNextPhotos() {
                if (this.current_page < this.last_page && !this.newImgLoading) {
                    this.newImgLoading = true;

                    this.$http
                        .get('/photos', {
                            params: {
                                per_page: 6,
                                page: this.current_page + 1,
                            },
                        })
                        .then((response) => {
                            this.newImgLoading = false;
                            this.photos = _concat(this.photos, response.data.data);
                            this.current_page = response.data.current_page;
                            this.last_page = response.data.last_page;

                            if (this.$refs.galleryTop) {
                                Vue.nextTick(() => {
                                    this.$refs.galleryTop.swiper.update();
                                    this.$refs.galleryThumbs.swiper.update();
                                    this.$refs.galleryTop.swiper.thumbs.swiper.update();
                                });
                            }
                        })
                        .catch(() => {
                            this.newImgLoading = false;
                        });
                }
            },

            downloadPhoto(img) {
                Vue.set(img, 'downloading', true);
                setTimeout(() => {
                    Vue.set(img, 'downloading', false);
                }, 1500);
                this.$downloadPhoto(img.photo_url, img.video).finally(() => {
                    Vue.set(img, 'downloading', false);
                });
            },

            visibilityChanged(isVisible) {
                isVisible ? this.getNextPhotos() : null;
            },

            downloadAll() {
                this.downloadAllLoading = true;
                setTimeout(() => {
                    this.downloadAllLoading = false;
                    this.$toastSuccess(this.$t('photos.all_photos_sent'));
                    this.$refs.popoverMore.close();
                }, 1500);
            },
        },
    };
</script>

<style lang="scss">
    .gallery-thumbs {
        .swiper-slide-thumb-active {
            border: 1px inset #f41e5d !important;
        }
    }
</style>

<style lang="scss" scoped>
    .gallery-top {
        width: 100%;
        height: 80%;

        .image-slide {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 80%;
            height: 100%;

            .image-actions {
                position: absolute;
                top: 0;
                width: 100%;
                height: 40px;
                background: rgba(0, 0, 0, 0.5);
                color: white;
                display: flex;
                align-items: center;
            }
        }
    }

    .gallery-thumbs {
        height: 20%;
        box-sizing: border-box;

        .image-slide {
            transition: all 0.15s ease-in;
            height: 100%;
            background-size: cover;
            background-position: center;
        }
    }

    .img-caption {
        position: absolute;
        bottom: 0;
        background: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, 0.7));
        background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7));
        width: 100%;
        padding: 15px;
        color: #fff;
    }
</style>