<template>
    <div>
        <div class="home-list">
            <div v-if="!filteredPosts.length" class="empty-data mt-5">
                <h2 class="grandhotel text-pink text-center p-3">{{ $t('home.empty_data') }}</h2>
            </div>
            <div v-else class="post" v-for="(post, i) in filteredPosts" :key="i">
                <!-- PRESENCES ARRIVAL -->
                <template v-if="post.type === 'arrival'">
                    <div class="post-header d-flex">
                        <div class="post-icon presence rounded-circle mr-2 mb-1 align-self-center">
                            <i class="fa fa-clock"></i>
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $t('daily_reports.attendances.drop_off') }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <template v-if="post.kid_gender === 'female'">
                            {{ $t('daily_reports.attendances.drop_off_sentence_f', {name: post.kid_first_name}) }}
                        </template>
                        <template v-else>
                            {{ $t('daily_reports.attendances.drop_off_sentence_m', {name: post.kid_first_name}) }}
                        </template>
                        <div v-if="post.drop_note" class="observation-note">
                            <i class="mr-1">{{ $t('daily_reports.observations.title_small') }}</i>{{post.drop_note}}
                        </div>
                    </div>
                </template>

                <!-- PRESENCES DEPARTURE -->
                <template v-if="post.type === 'departure'">
                    <div class="post-header d-flex">
                        <div class="post-icon presence rounded-circle mr-2 mb-1 align-self-center">
                            <i class="fa fa-clock fa-rotate-90"></i>
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $t('daily_reports.attendances.pick_up') }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <template v-if="post.kid_gender === 'female'">
                            {{ $t('daily_reports.attendances.pick_up_sentence_f', {name: post.kid_first_name}) }}
                        </template>
                        <template v-else>
                            {{ $t('daily_reports.attendances.pick_up_sentence_m', {name: post.kid_first_name}) }}
                        </template>
                        <div v-if="post.pick_up_note" class="observation-note">
                            <i class="mr-1">{{ $t('daily_reports.observations.title_small') }}</i>{{post.pick_up_note}}
                        </div>
                    </div>
                </template>

                <!-- PHOTOS -->
                <template v-else-if="post.type === 'photo'">
                    <div class="post-header d-flex">
                        <div class="post-icon photo rounded-circle mr-2 mb-1 align-self-center">
                            <i class="fad fa-camera-retro"></i>
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $t('daily_reports.photos.title') }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content position-relative" @click="showPicture(post)">
                        <div v-if="post.description" class="mb-2">{{post.description}}</div>
                        <hr class="my-2">
                        <div class="w-100 text-right">
                            <button
                                class="btn btn-pink btn-sm mb-1 mr-2 w-auto"
                                @click.stop="$openUrl(post.photo_url, true)"
                            >
                                <i class="fas fa-external-link-alt mr-2" />{{ $t('daily_reports.photos.open') }}
                            </button>
                            <button
                                v-if="!post.downloading"
                                class="btn btn-pink btn-sm mb-1 w-auto"
                                @click.stop="downloadPhoto(post)"
                            >
                                <i class="fas fa-cloud-download download mr-2" />{{ $t('daily_reports.photos.download') }}
                            </button>
                            <button
                                v-else
                                disabled
                                class="btn btn-pink btn-sm mb-1 w-auto"
                            >
                                <i class="fa fa-circle-notch fa-spin download mr-2" />{{ $t('daily_reports.photos.download') }}
                            </button>
                        </div>
                        <img :src="post.thumbnail_url" :alt="$t('daily_reports.photos.alt')" class="rounded">
                    </div>
                </template>

                <!-- VIDEOS -->
                <template v-else-if="post.type === 'video'">
                    <div class="post-header d-flex">
                        <div class="post-icon video rounded-circle mr-2 mb-1 align-self-center">
                            <i class="fad fa-film" />
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $t('daily_reports.videos.title') }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content position-relative">
                        <div v-if="post.description" class="mb-2">{{post.description}}</div>
                        <hr class="my-2">
                        <div class="w-100 text-right">
                            <button
                                class="btn btn-pink btn-sm mb-1 mr-2 w-auto"
                                @click.stop="$openUrl(post.photo_url, true)"
                            >
                                <i class="fas fa-external-link-alt mr-2" />{{ $t('daily_reports.photos.open') }}
                            </button>
                            <button
                                v-if="!post.downloading"
                                class="btn btn-pink btn-sm mb-1 w-auto"
                                @click.stop="downloadVideo(post)"
                            >
                                <i class="fas fa-cloud-download download mr-2" />{{ $t('daily_reports.videos.download') }}
                            </button>
                            <button
                                v-else
                                disabled
                                class="btn btn-pink btn-sm mb-1 w-auto"
                            >
                                <i class="fa fa-circle-notch fa-spin download mr-2" />{{ $t('daily_reports.videos.download') }}
                            </button>
                        </div>
                        <VideoPlayer
                            :url="post.photo_url"
                            :extension-type="post.type"
                            class="rounded"
                            style="max-height: 200px; object-fit: cover;"
                        />
                    </div>
                </template>

                <!-- BIBERON -->
                <template v-else-if="post.type === 'biberon'">
                    <div class="post-header d-flex">
                        <div class="post-icon biberon rounded-circle mr-2 mb-1 align-self-center">
                            <img src="./../../assets/img/icons/baby_bottle.svg" alt="Bottle" width="22px">
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $t('daily_reports.meals.baby_bottle.title') }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <span
                            v-if="post.kid_gender === 'female'"
                            v-html="$t('daily_reports.meals.baby_bottle.sentence_f', {name: post.kid_first_name, milk: post.milk})">
                        </span>
                        <span
                            v-else
                            v-html="$t('daily_reports.meals.baby_bottle.sentence_m', {name: post.kid_first_name, milk: post.milk})">
                        </span>
                        <br>
                        <span v-if="post.rating === 0">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.meals.sentence_rating_little_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.meals.sentence_rating_little_m', {name: post.kid_first_name}) }}
                            </template>
                        </span>
                        <span v-else-if="post.rating === 1">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.meals.sentence_rating_average_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.meals.sentence_rating_average_m', {name: post.kid_first_name}) }}
                            </template>
                        </span>
                        <span v-else-if="post.rating === 2">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.meals.sentence_rating_good_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.meals.sentence_rating_good_m', {name: post.kid_first_name}) }}
                            </template>
                        </span>
                        <div v-if="post.note" class="observation-note">
                            <i class="mr-1">{{ $t('daily_reports.observations.title_small') }}</i>{{post.note}}
                        </div>
                    </div>
                </template>

                <!-- BREASTFEEDING -->
                <template v-else-if="post.type === 'breastfeeding'">
                    <div class="post-header d-flex">
                        <div class="post-icon biberon rounded-circle mr-2 mb-1 align-self-center">
                            <img src="./../../assets/img/icons/breastfeeding.svg" alt="Breastfeeding" width="16px" class="ml-1">
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $t('daily_reports.meals.breastfeeding.title') }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <span v-if="post.rating === null">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.meals.sentence_rating_nothing_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.meals.sentence_rating_nothing_m', {name: post.kid_first_name}) }}
                            </template>
                        </span>
                        <span v-if="post.rating === 0">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.meals.sentence_rating_little_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.meals.sentence_rating_little_m', {name: post.kid_first_name}) }}
                            </template>
                        </span>
                        <span v-else-if="post.rating === 1">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.meals.sentence_rating_average_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.meals.sentence_rating_average_m', {name: post.kid_first_name}) }}
                            </template>
                        </span>
                        <span v-else-if="post.rating === 2">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.meals.sentence_rating_good_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.meals.sentence_rating_good_m', {name: post.kid_first_name}) }}
                            </template>
                        </span>
                        <div v-if="post.note" class="observation-note">
                            <i class="mr-1">{{ $t('daily_reports.observations.title_small') }}</i>{{post.note}}
                        </div>
                    </div>
                </template>

                <!-- MEALS -->
                <template v-else-if="post.type === 'meal'">
                    <div class="post-header d-flex">
                        <div class="post-icon meal rounded-circle mr-2 mb-1 align-self-center">
                            <img v-if="post.rating === null" src="./../../assets/img/icons/apple_0.svg" alt="Apple" width="19px">
                            <img v-else-if="post.rating === 0" src="./../../assets/img/icons/apple_0.svg" alt="Apple" width="19px">
                            <img v-else-if="post.rating === 1" src="./../../assets/img/icons/apple_1.svg" alt="Apple" width="19px">
                            <img v-else-if="post.rating === 2" src="./../../assets/img/icons/apple_2.svg" alt="Apple" width="19px">
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $t('daily_reports.meals.meal.title') }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <span v-if="post.rating === null">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.meals.sentence_rating_nothing_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.meals.sentence_rating_nothing_m', {name: post.kid_first_name}) }}
                            </template>
                        </span>
                        <span v-if="post.rating === 0">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.meals.sentence_rating_little_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.meals.sentence_rating_little_m', {name: post.kid_first_name}) }}
                            </template>
                        </span>
                        <span v-else-if="post.rating === 1">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.meals.sentence_rating_average_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.meals.sentence_rating_average_m', {name: post.kid_first_name}) }}
                            </template>
                        </span>
                        <span v-else-if="post.rating === 2">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.meals.sentence_rating_good_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.meals.sentence_rating_good_m', {name: post.kid_first_name}) }}
                            </template>
                        </span>
                        <div v-if="post.note" class="observation-note">
                            <i class="mr-1">{{ $t('daily_reports.observations.title_small') }}</i>{{post.note}}
                        </div>
                    </div>
                </template>

                <!-- ACTIVITY -->
                <template v-else-if="post.type === 'activity'">
                    <div class="post-header d-flex">
                        <div class="post-icon activity rounded-circle mr-2 mb-1 align-self-center">
                            <img src="./../../assets/img/icons/activity.svg" alt="Activity" width="22px">
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $tc('daily_reports.activities.title', 1) }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <template v-if="post.kid_gender === 'female'">
                            {{ $t('daily_reports.activities.sentence_f', {name: post.kid_first_name}) }}
                        </template>
                        <template v-else>
                            {{ $t('daily_reports.activities.sentence_m', {name: post.kid_first_name}) }}
                        </template>
                        <strong>{{post.name}}</strong>.
                        <div v-if="post.note" class="observation-note">
                            <i class="mr-1">{{ $t('daily_reports.observations.title_small') }}</i>{{post.note}}
                        </div>
                    </div>
                </template>

                <!-- NAPS -->
                <template v-else-if="post.type === 'nap'">
                    <div class="post-header d-flex">
                        <div class="post-icon nap rounded-circle mr-2 mb-1 align-self-center">
                            <img src="./../../assets/img/icons/moon_2.svg" alt="Moon" class="moon" width="22px">
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $tc('daily_reports.naps.title', 1) }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <template v-if="post.rating === null">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.naps.sentence_rating_nothing_f', {name: post.kid_first_name, from: simpleTime(post.started_at), to: simpleTime(post.ended_at)}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.naps.sentence_rating_nothing_m', {name: post.kid_first_name, from: simpleTime(post.started_at), to: simpleTime(post.ended_at)}) }}
                            </template>
                        </template>
                        <template v-else-if="post.ended_at">
                            <span
                                v-html="$t('daily_reports.naps.sentence_from_to_f', {name: post.kid_first_name, duration: getDuration(post.started_at, post.ended_at), from: simpleTime(post.started_at), to: simpleTime(post.ended_at)})"
                                v-if="post.kid_gender === 'female'">
                            </span>
                            <span
                                v-html="$t('daily_reports.naps.sentence_from_to_m', {name: post.kid_first_name, duration: getDuration(post.started_at, post.ended_at), from: simpleTime(post.started_at), to: simpleTime(post.ended_at)})"
                                v-else>
                            </span>
                        </template>
                        <template v-else>
                            <span
                                v-html="$t('daily_reports.naps.sentence_still_sleeping_m', {name: post.kid_first_name, from: simpleTime(post.started_at)})"
                                v-if="post.kid_gender === 'female'">
                            </span>
                            <span
                                v-html="$t('daily_reports.naps.sentence_still_sleeping_f', {name: post.kid_first_name, from: simpleTime(post.started_at)})"
                                v-else>
                            </span>
                        </template>
                        <div v-if="post.note" class="observation-note">
                            <i class="mr-1">{{ $t('daily_reports.observations.title_small') }}</i>{{post.note}}
                        </div>
                    </div>
                </template>

                <!-- TEMPERATURE -->
                <template v-else-if="post.type === 'temperature'">
                    <div class="post-header d-flex">
                        <div class="post-icon temperature rounded-circle mr-2 mb-1 align-self-center">
                            <img src="./../../assets/img/icons/thermometer.svg" alt="Thermometer" width="8px">
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $t('daily_reports.health.title_full') }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <span
                            v-html="$t('daily_reports.health.temperatures.sentence_f', {temperature: post.degree})"
                            v-if="post.kid_gender === 'female'">
                        </span>
                        <span
                            v-html="$t('daily_reports.health.temperatures.sentence_m', {temperature: post.degree})"
                            v-else>
                        </span>
                        <div v-if="post.note" class="observation-note">
                            <i class="mr-1">{{ $t('daily_reports.observations.title_small') }}</i>{{post.note}}
                        </div>
                    </div>
                </template>

                <!-- WEIGHT -->
                <template v-else-if="post.type === 'weight'">
                    <div class="post-header d-flex">
                        <div class="post-icon weight rounded-circle mr-2 mb-1 align-self-center">
                            <img src="./../../assets/img/icons/balance.svg" alt="Balance" width="22px">
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $t('daily_reports.health.title_full') }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <span
                            v-html="$t('daily_reports.health.weights.sentence_f', {kilogram: post.kilogram})"
                            v-if="post.kid_gender === 'female'">
                        </span>
                        <span
                            v-html="$t('daily_reports.health.weights.sentence_m', {kilogram: post.kilogram})"
                            v-else>
                        </span>
                        <div v-if="post.note" class="observation-note">
                            <i class="mr-1">{{ $t('daily_reports.observations.title_small') }}</i>{{post.note}}
                        </div>
                    </div>
                </template>

                <!-- DRUGS -->
                <template v-else-if="post.type === 'drug'">
                    <div class="post-header d-flex">
                        <div class="post-icon drug rounded-circle mr-2 mb-1 align-self-center">
                            <img src="./../../assets/img/icons/pills.svg" alt="Pill" width="22px">
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $t('daily_reports.health.title_full') }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <span
                            v-html="$t('daily_reports.health.drugs.sentence_f', {name: post.kid_first_name, drug: post.name})"
                            v-if="post.kid_gender === 'female'">
                        </span>
                        <span
                            v-html="$t('daily_reports.health.drugs.sentence_m', {name: post.kid_first_name, drug: post.name})"
                            v-else>
                        </span>
                        <div v-if="post.note" class="observation-note">
                            <i class="mr-1">{{ $t('daily_reports.observations.title_small') }}</i>{{post.note}}
                        </div>
                    </div>
                </template>

                <!-- HYGIENE -->
                <template v-else-if="post.type === 'diaper'">
                    <div class="post-header d-flex">
                        <div class="post-icon hygiene rounded-circle mr-2 mb-1 align-self-center">
                            <img v-if="post.category === null || post.isHygiene" src="./../../assets/img/icons/baby_diaper.svg" class="diaper ml-1" alt="Diaper"
                                 width="16px">
                            <img v-else-if="post.category === 'diaper'" src="./../../assets/img/icons/diaper.svg" class="diaper" alt="Diaper" width="23px">
                            <img v-else-if="post.category === 'potty'" src="./../../assets/img/icons/potty.svg" alt="Potty" width="22px">
                            <img v-else-if="post.category === 'toilet'" src="./../../assets/img/icons/toilet.svg" alt="Toilet" width="19px">
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $t('daily_reports.hygiene.title') }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <template v-if="post.category === 'diaper' && !post.isHygiene">
                            <template v-if="post.pee || post.poop">
                                <template v-if="post.kid_gender === 'female'">
                                    {{ $t('daily_reports.hygiene.sentence_diaper_f', {name: post.kid_first_name}) }}
                                </template>
                                <template v-else>
                                    {{ $t('daily_reports.hygiene.sentence_diaper_m', {name: post.kid_first_name}) }}
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="post.kid_gender === 'female'">
                                    {{ $t('daily_reports.hygiene.sentence_diaper_dry_f', {name: post.kid_first_name}) }}
                                </template>
                                <template v-else>
                                    {{ $t('daily_reports.hygiene.sentence_diaper_dry_m', {name: post.kid_first_name}) }}
                                </template>
                            </template>
                        </template>
                        <template v-if="post.category === 'potty'">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.hygiene.sentence_potty_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.hygiene.sentence_potty_m', {name: post.kid_first_name}) }}
                            </template>
                        </template>
                        <template v-if="post.category === 'toilet'">
                            <template v-if="post.kid_gender === 'female'">
                                {{ $t('daily_reports.hygiene.sentence_toilet_f', {name: post.kid_first_name}) }}
                            </template>
                            <template v-else>
                                {{ $t('daily_reports.hygiene.sentence_toilet_m', {name: post.kid_first_name}) }}
                            </template>
                        </template>

                        <template v-if="post.poop">
                            <br>
                            <span v-if="post.poop === 'hard'">{{ $t('daily_reports.hygiene.hard_stools') }}</span>
                            <span v-if="post.poop === 'normal'">{{ $t('daily_reports.hygiene.normal_stools') }}</span>
                            <span v-if="post.poop === 'soft'">{{ $t('daily_reports.hygiene.soft_stools') }}</span>
                            <span v-if="post.poop === 'liquid'">{{ $t('daily_reports.hygiene.liquid_stools') }}</span>
                        </template>

                        <div v-if="post.nose || post.eyes || post.ear || post.cream">
                            {{ $t('daily_reports.hygiene.care_provided') }}
                            <span v-if="post.nose">{{ $t('daily_reports.hygiene.nose') }}</span>
                            <span v-if="post.eyes">{{ $t('daily_reports.hygiene.eyes') }}</span>
                            <span v-if="post.ear">{{ $t('daily_reports.hygiene.ears') }}</span>
                            <span v-if="post.cream">{{ $t('daily_reports.hygiene.cream') }}</span>
                        </div>

                        <div v-if="post.note" class="observation-note">
                            <i class="mr-1">{{ $t('daily_reports.observations.title_small') }}</i>{{post.note}}
                        </div>
                    </div>
                </template>

                <!-- OBSERVATIONS -->
                <template v-else-if="post.type === 'observation'">
                    <div class="post-header d-flex">
                        <div class="post-icon observation rounded-circle mr-2 mb-1 align-self-center">
                            <img src="./../../assets/img/icons/observation.svg" alt="Observation" width="17px">
                        </div>
                        <div class="w-75 align-self-center">
                            <div class="post-title">
                                {{ $t('daily_reports.observations.title') }}
                            </div>
                            <div class="post-subtitle">
                                <small class="text-muted">{{post.kid_first_name}} - {{post.done_at | simpleTime($t('date_format.hours_minutes'))}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        {{post.note}}
                    </div>
                </template>
            </div>
        </div>

        <custom-modal id="modalPhoto" :show-header="false" body-style="padding: 5px;">
            <template slot="body">
                <div v-if="currentPhoto" class="position-relative">
                    <button type="button" class="close close-photo" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <img :src="currentPhoto.photo_url" width="100%" class="rounded-10" :alt="$t('daily_reports.photos.alt')">
                    <div class="img-caption rounded-10">
                        <h6 v-if="currentPhoto.description" class="m-1 w-75">{{currentPhoto.description}}</h6>
                        <p class="small m-1 w-75">{{currentPhoto.taken_at | formatDate($t('date_format.days_months_years.simple'))}}</p>
                        <i v-if="!currentPhoto.downloading" @click="downloadPhoto(currentPhoto)" class="far fa-download download mb-1"></i>
                        <i v-else class="fa fa-circle-notch fa-spin download mb-1"></i>
                    </div>
                </div>
            </template>
        </custom-modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import $ from 'jquery';
    import i18n from '@/i18n';

    import _orderBy from 'lodash/orderBy';
    import _concat from 'lodash/concat';

    import VideoPlayer from '@/components/Medias/VideoPlayer.vue';

    export default {
        components: {
            VideoPlayer
        },
        props: {
            kids: {},
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                posts: [],
                currentPhoto: {},
            }
        },
        computed: {
            filteredPosts() {
                let allPosts = [];
                this.kids.forEach((kid) => {
                    let posts = [];

                    if (kid.presences && kid.presences.length) {
                        let arrivals = [];
                        let departures = [];

                        kid.presences.forEach((presence) => {
                            Vue.set(presence, 'done_at', presence.droped_at);
                            Vue.set(presence, 'type', 'arrival');
                            arrivals.push(presence);

                            if (presence.picked_up_at) {
                                let departure = Object.assign({}, presence);
                                Vue.set(departure, 'done_at', presence.picked_up_at);
                                Vue.set(departure, 'type', 'departure');
                                departures.push(departure);
                            }
                        });
                        posts = _concat(posts, arrivals);
                        posts = _concat(posts, departures);
                    }

                    if (kid.meals && kid.meals.length) {
                        kid.meals.forEach((meal) => {
                            meal.milk !== null ? Vue.set(meal, 'type', 'biberon') : Vue.set(meal, 'type',
                                'meal');
                            meal.breastfeeding ? Vue.set(meal, 'type', 'breastfeeding') : null;
                        });
                        posts = _concat(posts, kid.meals);
                    }

                    if (kid.activities && kid.activities.length) {
                        kid.activities.forEach((activity) => {
                            Vue.set(activity, 'type', 'activity');
                        });
                        posts = _concat(posts, kid.activities);
                    }

                    if (kid.naps && kid.naps.length) {
                        kid.naps.forEach((nap) => {
                            Vue.set(nap, 'done_at', nap.started_at);
                            Vue.set(nap, 'type', 'nap');
                        });
                        posts = _concat(posts, kid.naps);
                    }

                    if (kid.temperatures && kid.temperatures.length) {
                        kid.temperatures.forEach((temperature) => {
                            Vue.set(temperature, 'type', 'temperature');
                        });
                        posts = _concat(posts, kid.temperatures);
                    }

                    if (kid.drugs && kid.drugs.length) {
                        kid.drugs.forEach((drug) => {
                            Vue.set(drug, 'type', 'drug');
                        });
                        posts = _concat(posts, kid.drugs);
                    }

                    if (kid.weights && kid.weights.length) {
                        kid.weights.forEach((weight) => {
                            Vue.set(weight, 'type', 'weight');
                        });
                        posts = _concat(posts, kid.weights);
                    }

                    if (kid.diapers && kid.diapers.length) {
                        kid.diapers.forEach((diaper) => {
                            if (diaper.type === null) {
                                Vue.set(diaper, 'isHygiene', true);
                            }

                            Vue.set(diaper, 'category', diaper.type);
                            Vue.set(diaper, 'type', 'diaper');
                        });
                        posts = _concat(posts, kid.diapers);
                    }

                    if (kid.photos && kid.photos.length) {
                        kid.photos.forEach((photo) => {
                            Vue.set(photo, 'done_at', photo.taken_at);

                            if(photo.video) {
                                Vue.set(photo, 'type', 'video');
                            } else {
                                Vue.set(photo, 'type', 'photo');
                            }
                        });
                        posts = _concat(posts, kid.photos);
                    }

                    if (kid.observations && kid.observations.length) {
                        kid.observations.forEach((obs) => {
                            Vue.set(obs, 'type', 'observation');
                        });
                        posts = _concat(posts, kid.observations);
                    }

                    posts.forEach((post) => {
                        Vue.set(post, 'kid_first_name', this.capizalize(kid.first_name));
                        Vue.set(post, 'kid_gender', kid.gender);
                        allPosts.push(post);
                    });
                });

                return _orderBy(allPosts.filter((item) => item.done_at !== null), 'done_at', ['asc']);
            }
        },

        methods: {
            showPicture(photo) {
                this.currentPhoto = photo;
                $('#modalPhoto').modal();
            },

            downloadPhoto(img) {
                Vue.set(img, 'downloading', true);
                setTimeout(() => {
                    Vue.set(img, 'downloading', false);
                }, 1500);
                this.$downloadPhoto(img.photo_url).finally(() => {
                    Vue.set(img, 'downloading', false);
                });
            },

            downloadVideo(video) {
                Vue.set(video, 'downloading', true);
                this.$downloadPhoto(video.photo_url, true).finally(() => {
                    Vue.set(video, 'downloading', false);
                });
            },

            getDuration(start, end) {
                let time1 = Number.isInteger(start) ? moment.unix(start) : moment(start);
                let time2 = Number.isInteger(end) ? moment.unix(end) : moment(end);
                let tempTime = moment.duration(time2.diff(time1));

                let totalMinutes = (tempTime.hours() * 60) + tempTime.minutes();
                if (totalMinutes >= 60) {
                    let humanTime = tempTime.hours() + 'h';
                    if (tempTime.minutes() > 0) {
                        humanTime += tempTime.minutes() > 10 ? tempTime.minutes() : '0' + tempTime.minutes();
                    }

                    return humanTime;
                } else if (totalMinutes <= 0 || isNaN(tempTime)) {
                    return 'Zzz'
                } else {
                    return totalMinutes + 'min';
                }
            },

            simpleTime(timestamp) {
                !Number.isInteger(timestamp) ? timestamp = moment(timestamp).unix() : null;
                let isValid = moment.unix(timestamp).isValid();
                let date = moment.unix(timestamp);
                let time = date.format(this.$t('date_format.hours_minutes'));
                let validTime = isValid ? time : '-:-';

                if (i18n.locale === 'es') {
                    return date.hours() === 13 ? 'la ' + validTime : 'las ' + validTime;
                }
                return validTime;
            },

            capizalize(str) {
                let lower = str ? str.toLowerCase() : '';
                return lower.replace(/(^|[\s-])\S/g, (match) => {
                    return match.toUpperCase();
                });
            },
        }
    }
</script>


<style lang="scss" scoped>
    .home-list {
        background: #F2E1FF;

        .empty-data {
            padding: 20px;
            background: white;
        }

        .post {
            background: white;
            margin: 8px 0;
            padding: 10px;

            &:first-child {
                margin-top: 0;
            }

            .post-header {
                height: 50px;

                .post-icon {
                    width: 35px;
                    height: 35px;
                    padding: 5px 8px;
                    position: relative;

                    img {
                        position: absolute;
                        height: 100%;
                        top: 0;
                        left: 5px;
                    }

                    &.photo {
                        border: 1px solid #FF268E;

                        .fa, .fal, .fad, .fas, .far {
                            color: #FF268E;
                        }
                    }

                    &.video {
                        border: 1px solid #ff26ed;

                        .fa, .fal, .fad, .fas, .far {
                            color: #ff26ed;
                        }
                    }

                    &.presence {
                        border: 1px solid #584ee0;

                        .fa, .fal, .fad, .fas, .far {
                            color: #584ee0;
                            font-size: 21px;
                            margin-left: -2px;
                            margin-top: 2px;
                        }
                    }

                    &.biberon {
                        border: 1px solid #5cb4f6;
                    }

                    &.meal {
                        border: 1px solid #ed4676;

                        img {
                            margin-left: 2px;
                        }
                    }

                    &.nap {
                        border: 1px solid #5755ed;

                        img {
                            margin-left: -1px;
                        }
                    }

                    &.temperature {
                        border: 1px solid #ed4377;

                        img {
                            margin-left: 8px;
                        }
                    }

                    &.weight {
                        border: 1px solid #8f6fb6;
                    }

                    &.drug {
                        border: 1px solid #ed4377;
                    }

                    &.hygiene {
                        border: 1px solid #8867ad;

                        .diaper {
                            top: 1px;
                        }
                    }

                    &.activity {
                        border: 1px solid #ef7529;
                    }

                    &.observation {
                        border: 1px solid #89DBF3;

                        img {
                            left: 9px;
                        }
                    }
                }

                .post-title {
                    line-height: 1;
                }

                .post-subtitle {
                    line-height: 1;
                }
            }

            .post-content {
                img {
                    width: 100%;
                    max-height: 200px;
                    object-fit: cover;
                }

                .observation-note {
                    margin-top: 7px;
                    padding-top: 5px;
                    border-top: 1px solid #DDD;
                    font-size: 14px;
                    color: #406588;
                }
            }
        }
    }

    .img-caption {
        position: absolute;
        bottom: 0;
        background: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, 0.7));
        background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7));
        width: 100%;
        padding: 15% 0 10px 15px;
        color: #fff;

        &.small-caption {
            background: linear-gradient(180deg, transparent, rgba(114, 114, 114, 0.4));
            padding: 25% 0 10px 15px;
        }

        .download {
            position: absolute;
            right: 20px;
            bottom: 13px;
            font-size: 25px;
        }
    }

    .rounded-10 {
        border-radius: 10px;
    }

    .close-photo {
        background: black;
        color: white;
        font-size: 27px !important;
        padding: 0px 6px;
        padding-bottom: 5px;
        right: -5px !important;
        top: -10px !important;
        width: 35px !important;
        height: 35px;
        border-radius: 50%;
        opacity: 1;
        border: 2px solid white;
    }
</style>
