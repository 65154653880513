<template>
    <div
        class="btn btn-purple btn-block btn-date position-relative"
        @click="toggle">
        <span
            class="w-100 text-center text-white position-absolute text-capitalize"
            style="left: 0; pointer-events: none;">
            {{ date | formatDate($t('date_format.days_months_hours.readable')) }}
        </span>
        <flat-pickr
            ref="picker"
            v-model="date"
            :config="config"
            class="w-100 h-100 m-0 text-center bg-transparent text-transparent border-0 btn btn-purple btn-block btn-date"/>
    </div>
</template>

<script>
    import moment from 'moment';

    import flatPickr from 'vue-flatpickr-component';
    import { French as fr } from 'flatpickr/dist/l10n/fr.js';
    import { english as en } from 'flatpickr/dist/l10n/default.js';
    import { Spanish as es } from 'flatpickr/dist/l10n/es.js';
    import { German as de } from 'flatpickr/dist/l10n/de.js';
    import { Italian as it } from 'flatpickr/dist/l10n/it.js';

    export default {
        components: {
            flatPickr,
        },
        props: {
            value: {},
            id: {}
        },
        data: () => ({
            date: null,
            config: {
                wrap: true,
                altInput: true,
                altInputClass: 'bg-transparent text-transparent border-0 pointer',
                altFormat: 'D j M Y - H:i',
                dateFormat: 'Y-m-dTH:i',
                allowInput: true,
                clickOpens: false,
                enableTime: true,
                time_24hr: false,
                locale: fr,
            }, 
        }),
        created() {
            let lang = window.localStorage.getItem('lang');
            switch (lang) {
                case 'fr':
                    this.config.locale = fr;
                    this.config.time_24hr = true;
                    return;
                case 'en':
                    this.config.locale = en;
                    this.config.time_24hr = false;
                    return;
                case 'es':
                    this.config.locale = es;
                    this.config.time_24hr = true;
                    return;
                case 'de':
                    this.config.locale = de;
                    this.config.time_24hr = true;
                    return;
                case 'it':
                    this.config.locale = it;
                    this.config.time_24hr = true;
                    return;
                default:
                    this.config.locale = fr;
                    this.config.time_24hr = true;
            }
        },
        mounted() {
            this.date = moment(this.value).format('YYYY-MM-DDTHH:mm');
        },
        watch: {
            value(val) {
                this.date = moment(val, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm');
            },
            date(val) {
                this.$emit('input', val);
            }
        },
        methods: {
            toggle() {
                this.$refs.picker.fp.toggle();
            },
        }
    };
</script>

<style lang="scss" scoped>
    .btn-date {
        border-radius: 20px;
        font-size: 15px;
        font-weight: 500;
        height: 40px;
    }
</style>

<style lang="scss">
    .flatpickr-day {
        &.selected {
            background-color: #f22c84 !important;
            border-color: #f22c84 !important;
        }

        &.today:not(.selected) {
            border-color: #188fd4 !important;
        }
    }
</style>
