Number.isInteger = Number.isInteger || function (value) {
  return typeof value === 'number' &&
    isFinite(value) &&
    Math.floor(value) === value;
};

import Vue from 'vue';

import Framework7 from 'framework7/framework7-lite.esm.bundle';
import Framework7Vue from 'framework7-vue';

import App from './App';
import mixin from './utils/mixin';
import i18n from './i18n';

import { ObserveVisibility } from 'vue-observe-visibility';
Vue.directive('observe-visibility', ObserveVisibility)

Framework7.use(Framework7Vue);

Vue.mixin(mixin);

require('@/utils/bootstrap');
require('@/utils/bus');
require('@/utils/http');
require('@/utils/notifications');
require('@/utils/filters');
require('@/utils/components');

new Vue({
  el: '#app',
  template: '<App/>',
  i18n,

  components: {
    App
  }
});