<template>
    <div class="unlink-account pt-5">
        <h2 class="grandhotel text-center p-2">{{ $t('access.account_not_sync') }}</h2>
        <h5 class="text-center">{{ $t('access.no_access') }}</h5>
        <h5 class="text-center text-pink" v-if="user.email">{{ user.email }}</h5>
    </div>
</template>

<script>
    export default {
        props: {
            value: {}
        },
        data() {
            return {
                user: {},
            }
        },
        mounted() {
            this.getUser();
        },
        methods: {
            getUser() {
                this.$http.get('/user').then((response) => {
                    if (response.data.nursery_id !== null) {
                        this.$http_bus.initNursery(response.data.nursery_id);
                        this.$emit('input', true);
                    }
                    this.user = response.data;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .unlink-account {
        height: 100%;
        background: #f7f7f7;
    }
</style>