import Vue from 'vue';

let EventBus = new Vue();

Object.defineProperties(Vue.prototype, {
    $bus: {
        get: () => {
            return EventBus;
        }
    }
});