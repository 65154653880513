<template>
    <div>
        <f7-page name="login" class="login">
            <f7-block class="form-block rounded" inset>
                <div class="text-center">
                    <img src="./../assets/img/meeko_head.png" class="text-center" alt="Meeko" width="40%" style="max-width: 200px;">
                </div>
                <h2 class="grandhotel text-center text-purple-title">{{ $t('login.title') }}</h2>
                <div class="form-row">
                    <label>{{ $t('login.email') }}</label>
                    <input type="email" v-model="login.email" class="form-control" :placeholder="$t('login.email_placeholder')">
                </div>
                <div class="form-row mt-3">
                    <label>{{ $t('login.password') }}</label>
                    <input type="password" v-model="login.password" class="form-control" :placeholder="$t('login.password_placeholder')">
                </div>
                <div class="form-row mt-4">
                    <button @click="signin" class="btn btn-block btn-purple">
                        <span v-if="!login.loading">{{ $t('login.sign_in') }}</span>
                        <i v-else class="fa fa-circle-notch fa-spin"></i>
                    </button>
                </div>
                <div class="mt-3 mb-2">
                    <button @click="openForgotPassword" class="btn btn-link btn-block text-pink btn-sm">{{ $t('forgot_password.button') }}</button>
                </div>
                <hr>
                <div class="mt-4">
                    <button @click="openRegister" class="btn btn-block btn-pink">{{ $t('sign_up.button') }}</button>
                </div>
            </f7-block>
        </f7-page>

        <f7-login-screen class="register" :opened="registerOpened">
            <f7-block class="form-block rounded" inset>
                <h2 class="grandhotel text-center text-purple-title">{{ $t('sign_up.title') }}</h2>
                <div class="form-row mt-4">
                    <label>{{ $t('sign_up.email') }}</label>
                    <input type="email" v-model="register.email" class="form-control" :placeholder="$t('sign_up.email_placeholder')">
                </div>
                <div class="form-row mt-3">
                    <label>{{ $t('sign_up.password') }}</label>
                    <input type="password" v-model="register.password" class="form-control" :placeholder="$t('sign_up.password_placeholder')">
                    <small class="pl-1 pt-1">
                        <i class="fa fa-info-circle mr-1"></i>
                        <i>{{ $t('sign_up.password_tip') }}</i>
                    </small>
                </div>
                <div class="form-row mt-2">
                    <label>{{ $t('sign_up.password_confirm') }}</label>
                    <input type="password" v-model="register.confirm_password" class="form-control" :placeholder="$t('sign_up.password_confirm_placeholder')">
                </div>
                <div class="form-row mt-4">
                    <i18n path="sign_up.term" tag="small">
                        <a href="https://meeko.pro/legals/terms" @click="$openUrl('https://meeko.pro/legals/terms')" target="_blank">{{ $t('sign_up.tos') }}</a>
                    </i18n>
                    <!-- <small>En créant votre compte, vous acceptez les
                        <a href="https://meeko.pro/legals/terms" @click="$openUrl('https://meeko.pro/legals/terms')" target="_blank">conditions générales d'utilisation</a> de Meeko.</small> -->
                </div>
                <div class="form-row mt-3">
                    <button @click="signup" class="btn btn-block btn-pink">
                        <span v-if="!register.loading">{{ $t('sign_up.confirm') }}</span>
                        <i v-else class="fa fa-circle-notch fa-spin"></i>
                    </button>
                </div>
                <div class="form-row mt-3">
                    <button @click="registerOpened = false" class="btn btn-block btn-default">{{ $t('sign_up.cancel') }}</button>
                </div>
            </f7-block>
        </f7-login-screen>

        <f7-login-screen class="forgot-password" :opened="forgotPasswordOpened">
            <f7-block class="form-block rounded" inset>
                <h2 class="grandhotel text-center text-purple-title">{{ $t('forgot_password.title') }}</h2>
                <div class="form-row mt-4">
                    <div>
                        <i class="fa fa-info-circle mr-1"></i>
                        <span>{{ $t('forgot_password.tip') }}</span>
                    </div>
                </div>
                <div class="form-row mt-3 mb-2">
                    <label>{{ $t('forgot_password.email') }}</label>
                    <input type="email" v-model="forgot.email" class="form-control" :placeholder="$t('forgot_password.email_placeholder')">
                </div>
                <div class="form-row mt-4">
                    <button @click="resetPassword" class="btn btn-block btn-pink">
                        <span v-if="!forgot.loading">{{ $t('forgot_password.confirm') }}</span>
                        <i v-else class="fa fa-circle-notch fa-spin"></i>
                    </button>
                </div>
                <div class="form-row mt-3">
                    <button @click="forgotPasswordOpened = false" class="btn btn-block btn-default">{{ $t('forgot_password.cancel') }}</button>
                </div>
            </f7-block>
        </f7-login-screen>
    </div>
</template>

<script>
    import { f7Page, f7Block, f7LoginScreen } from 'framework7-vue';

    export default {
        components: {
            f7Page,
            f7Block,
            f7LoginScreen,
        },
        data() {
            return {
                login: {
                    email: null,
                    password: null,
                    loading: false
                },
                register: {
                    email: null,
                    password: null,
                    confirm_password: null,
                    loading: false
                },
                forgot: {
                    email: null,
                    loading: false
                },
                registerOpened: false,
                forgotPasswordOpened: false
            }
        },
        methods: {
            signin() {
                if (!this.login.email || !this.login.password) {
                    return;
                }


                this.login.loading = true;

                this.$http.post('/login', {
                    email: this.login.email,
                    password: this.login.password
                }).then((response) => {
                    this.login.loading = false;
                    this.$http_bus.initApi(response.data);
                    this.$bus.$emit('login', true);
                }).catch((e) => {
                    this.login.loading = false;
                    if (e.response && e.response.status === 403) {
                        this.$toastWarning(this.$t('login.error_verify_account'), {
                            action: {
                                text: this.$t('login.send_again'),
                                onClick: () => {
                                    this.sendAgain();
                                }
                            },
                        });
                    }
                });
            },

            sendAgain() {
                this.$http_base.get('/activation/resend', {
                    params: {
                        email: this.login.email
                    }
                }).then(() => {
                    this.$toastSuccess(this.$t('sign_up.confirm_success_again'));
                });
            },

            signup() {
                if (this.register.loading) {
                    return;
                }

                if (!this.register.email || !this.register.password || !this.register.confirm_password) {
                    return;
                }

                if (this.register.password.length < 6) {
                    this.$toastWarning(this.$t('sign_up.password_error_number_of_characters'));
                    return;
                }

                if (this.register.password !== this.register.confirm_password) {
                    this.$toastWarning(this.$t('sign_up.password_error_not_matching'));
                    return;
                }

                this.register.loading = true;

                this.$http.post('/register', {
                    email: this.register.email,
                    password: this.register.password
                }).then(() => {
                    this.register.loading = false;
                    this.registerOpened = false;
                    this.login.email = this.register.email;
                    this.$toastSuccess(this.$t('sign_up.confirm_success'));
                }).catch(() => {
                    this.register.loading = false;
                });
            },

            resetPassword() {
                if (!this.forgot.email) {
                    return;
                }

                this.forgot.loading = true;

                this.$http.post('/password/email', {
                    email: this.forgot.email
                }).then(() => {
                    this.forgot.loading = false;
                    this.forgotPasswordOpened = false;
                    this.$toastSuccess(this.$t('forgot_password.confirm_success'));
                }).catch(() => {
                    this.forgot.loading = false;
                });
            },

            openRegister() {
                this.register.email = null;
                this.register.password = null;
                this.register.confirm_password = null;
                this.registerOpened = true;
            },

            openForgotPassword() {
                this.forgot.email = this.login.email;
                this.forgotPasswordOpened = true;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "./../sass/variables";
    @import "./../sass/utilities";

    .login {
        @include gradient45(#ff6565, $pink);
    }

    .register {
        @include gradient45(#ff6565, $pink);
    }

    .forgot-password {
        @include gradient45(#ff6565, $pink);
    }

    .form-block {
        background: white;
        padding: 15px 20px;
        margin-top: 10vh;
        margin-bottom: 0;

        label {
            color: $brand-purple;
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 2px;
        }
    }
</style>