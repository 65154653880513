<template>
    <f7-panel ref="panel" right cover class="panel pt-2" @panel:open="showPanel = true" @panel:close="showPanel = false" @panel:backdrop-click="showPanel = false">
        <div class="text-right px-3">
            <i
                @click="closePanel"
                class="fa fa-times pointer mt-1"
                style="font-size: 20px;"/>
        </div>

        <div v-if="switchingNursery" class="p-3">
            <div>
                <loader v-if="loading"></loader>
                <template v-else>
                    <h6 @click="switchingNursery = false" class="text-purple">
                        <i class="fa fa-arrow-left mr-2"></i>{{ $t('settings.choose_nursery') }}
                    </h6>
                    <hr class="mt-1">
                    <div v-for="(nursery, i) in nurseries" :key="'nursery'+i" @click="switchNursery(nursery)" class="p-2 border rounded mt-3">
                        <i class="fa fa-home-heart mr-2"></i>{{nursery.name}}
                        <span v-if="nursery.city" class="small" style="white-space: nowrap;"> - {{nursery.city}}</span>
                    </div>
                </template>
            </div>
        </div>

        <div
            v-else-if="switchingLang"
            class="p-3">
            <loader v-if="loading"></loader>
            <template v-else>
                <h6 @click="switchingLang = false" class="text-purple">
                    <i class="fa fa-arrow-left mr-2"></i>{{ $t('settings.choose_language') }}
                </h6>
                <hr class="mt-1">
                <div
                    v-for="(lang, i) in langs"
                    :key="'lang'+i"
                    class="p-2 border rounded mt-3"
                    @click="switchLang(lang.value)">
                    {{ lang.name }}
                </div>
            </template>
        </div>

        <div
            v-else-if="showDeleteAccount"
            class="p-3">
            <loader v-if="loading"></loader>
            <template v-else>
                <h6 @click="showDeleteAccount = false" class="text-purple">
                    <i class="fa fa-arrow-left mr-2"/>{{ $t('settings.delete_account.title') }}
                </h6>
                <hr class="mt-1">
                <div class="mt-3">
                    <div class="small">{{ $t('settings.delete_account.confirm_description') }}</div>
                    <div class="mt-2">
                        <label class="small">{{ $t('sign_up.password') }}</label>
                        <input type="password" v-model="password" class="form-control form-control-sm" :placeholder="$t('sign_up.password_placeholder')">
                    </div>
                    <button @click="deleteAccount()" class="btn btn-sm btn-block btn-danger mt-4">
                        <i v-if="deleteAccountLoading" class="fa fa-circle-notch fa-spin mr-2"/>
                        <i v-else class="fa fa-exclamation-triangle mr-2"/>
                        {{ $t('settings.delete_account.delete_button') }}
                    </button>
                    <button @click="showDeleteAccount = false" class="btn btn-sm btn-block btn-dark mt-3">{{ $t('settings.delete_account.cancel_button') }}</button>
                </div>
            </template>
        </div>

        <div v-else>
            <div v-if="nursery" @click="switchingNursery = true" class="text-center px-2 pt-1">
                <h5 class="text-purple-title">{{nursery.name}}</h5>
                <h5 class="small">
                    <span v-if="nursery.city" style="white-space: nowrap;">{{nursery.city}}</span>
                    <span class="text-primary" style="white-space: nowrap;">
                        &nbsp;-&nbsp;
                        <i class="fa fa-retweet mr-1"></i>{{ $t('settings.switch') }}
                    </span>
                </h5>
                <button @click="logout" class="btn btn-sm btn-block btn-pink mt-3">{{ $t('settings.logout') }}</button>
                <hr>
            </div>
            <div v-else class="text-center px-2 pt-1">
                <button @click="logout" class="btn btn-sm btn-block btn-pink mt-3">{{ $t('settings.logout') }}</button>
                <hr>
            </div>

            <div class="row mt-3 mx-0">
                <div class="notifications col-12">
                    <h6 class="text-left text-pink text-uppercase" style="font-size: 11px;">{{ $t('settings.my_account_title') }}</h6>

                    <h6 v-if="user && user.email" class="mb-2" style="word-break: break-all;">{{ user.email }}</h6>
                    <button @click="password = ''; showDeleteAccount = true" class="btn btn-sm btn-block btn-dark" style="font-size: 12px;">{{ $t('settings.delete_account.delete_button_dots') }}</button>

                    <h6 class="text-left text-pink text-uppercase mt-4" style="font-size: 11px;">{{ $t('settings.language') }}</h6>
                    <button
                        id="btn-lang"
                        class="btn btn-outline-primary btn-sm"
                        @click="switchingLang = true">
                        {{ getLangName(selectedLang) }}
                    </button>
                </div>

                <div class="notifications col-12 mt-4">
                    <h6 class="text-left text-pink text-uppercase" style="font-size: 11px;">{{ $t('settings.notifications.title') }}</h6>

                    <div @click="switchNotif('new_photo')" class="notif-item" :class="{'active': notifIncluded('new_photo')}">
                        <i class="fa fa-photo-video mr-2"></i>{{ $t('settings.notifications.medias') }}
                    </div>
                    <div @click="switchNotif('new_post')" class="notif-item" :class="{'active': notifIncluded('new_post')}">
                        <i class="fa fa-calendar-alt mr-2"></i>{{ $t('settings.notifications.news') }}
                    </div>
                    <div @click="switchNotif('new_message')" class="notif-item" :class="{'active': notifIncluded('new_message')}">
                        <i class="fa fa-comments mr-2"></i>{{ $t('settings.notifications.messages') }}
                    </div>
                    <div @click="switchNotif('new_document')" class="notif-item" :class="{'active': notifIncluded('new_document')}">
                        <i class="fa fa-copy mr-2"></i>{{ $t('settings.notifications.documents') }}
                    </div>
                    <div @click="switchNotif('new_invoice')" class="notif-item" :class="{'active': notifIncluded('new_invoice')}">
                        <i class="fa fa-file-invoice mr-2"></i>{{ $t('settings.notifications.invoices') }}
                    </div>
                </div>
            </div>
        </div>
    </f7-panel>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import i18n from '@/i18n';

    import _includes from 'lodash/includes';

    import { f7Panel } from 'framework7-vue';

    export default {
        components: {
            f7Panel,
        },
        props: {
            value: Boolean
        },
        data() {
            return {
                showPanel: false,
                nurseries: this.$nurseries,
                nursery: this.$nursery,
                user: this.$user,
                login: localStorage.getItem("connected") === 'true',
                loading: false,
                switchingNursery: false,
                switchingLang: false,
                showDeleteAccount: false,
                deleteAccountLoading: false,
                password: '',
                via_broadcast: [],
                selectedLang: window.localStorage.getItem('lang'),
                langs: [{
                    name: '🇫🇷 Français',
                    value: 'fr',
                }, {
                    name: '🇩🇪 Deutsch',
                    value: 'de',
                }, {
                    name: '🇬🇧 English',
                    value: 'en',
                }, {
                    name: '🇪🇸 Español',
                    value: 'es',
                }],
            }
        },
        watch: {
            showPanel(val) {
                this.$emit('input', val);
                if(val) {
                    this.getNurseries();
                }
            }
        },
        mounted() {
            if (this.user) {
                this.via_broadcast = this.user.via_broadcast;
            }
        },
        beforeDestroy() {
            this.$refs.panel.f7Panel.close(false);
        },
        methods: {
            getNurseries() {
                if (this.login) {
                    this.loading = true;
                    this.$http.get('/nurseries').then((response) => {
                        this.loading = false;
                        Vue.prototype.$nurseries = response.data;
                        this.nurseries = response.data;
                        if (response.data.length) {
                            Vue.prototype.$nursery = response.data.find((item) => item.id.toString() === this.$http_bus.nursery_id);
                            this.nursery = Vue.prototype.$nursery;
                        }
                    }).catch(() => {
                        this.loading = false;
                    });
                }
            },

            switchNursery(nursery) {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                this.$http.put('/user', {
                    nursery_id: nursery.id
                }).then(() => {
                    this.nursery = nursery;
                    Vue.prototype.$nursery = nursery;
                    this.$http_bus.initNursery(nursery.id);
                    this.switchingNursery = false;
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            },

            switchNotif(notif) {
                if(this.notifIncluded(notif)) {
                    this.via_broadcast = this.via_broadcast.filter((item) => item !== notif);
                } else {
                    this.via_broadcast.push(notif);
                }

                this.$http.put('/user', {
                    via_broadcast: this.via_broadcast
                }).then((response) => {
                    this.via_broadcast = response.data.via_broadcast;
                    this.$toastSuccess(this.$t('settings.notifications.update_success'));
                });
            },

            switchLang(lang) {
                i18n.locale = lang ? lang : 'fr';
                moment.locale(i18n.locale);
                window.localStorage.setItem('lang', i18n.locale);

                this.selectedLang = lang;
                this.switchingLang = false;
            },

            getLangName(selectedLang) {
                let lang = this.langs.find((item) => item.value === selectedLang);
                return lang ? lang.name : this.$t('settings.choose_language');
            },

            notifIncluded(notif) {
                return _includes(this.via_broadcast, notif);
            },

            closePanel() {
                this.$refs.panel.close(true);
            },

            deleteAccount() {
                if (this.deleteAccountLoading) {
                    return;
                }
                this.deleteAccountLoading = true;

                this.$http.delete('/user', {
                    params: {
                        password: this.password
                    }
                }).then(() => {
                    this.deleteAccountLoading = false;
                    this.showDeleteAccount = false;
                    this.logout();
                }).catch(() => this.deleteAccountLoading = false);
            },

            logout() {
                this.$refs.panel.close(false);

                setTimeout(() => {
                    this.$bus.$emit('login', false);
                    this.$http_bus.resetApi();
                    this.$notification_bus.resetAllLocalBadges();
                }, 600);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .panel {
        margin-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
    }

    .notif-item {
        border: 1px solid #6535e8;
        border-radius: 5px;
        padding: 6px 8px;
        margin-top: 10px;
        color: #6738e8;
        opacity: .6;
        transition: all .2s ease-in;
        &.active {
            background: #6535e8;
            color: white;
            opacity: 1;
        }
    }
</style>
